import {
  Check,
  Circle,
  Clear,
  Close,
  ControlPointDuplicate,
  CrisisAlert,
  Difference,
  Inventory,
  KeyboardTab,
  Menu,
  NewReleases,
  NoteAdd,
  NotInterestedRounded,
  PriorityHighOutlined,
  Task,
  Warning
} from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { orange } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { UserRoles } from '@one/UserRoles'
import {
  artikelColumnDecorator,
  ArtikelDefaultColumnsProps,
  useArtikelColumns
} from '@one/components/Artikel/ArtikelColumn'
import { ArtikelSearchDialog } from '@one/components/Artikel/ArtikelSearchDialog'
import { useArtikelLabelEdit } from '@one/components/Label/useArtikelLabelEdit'
import { ArtikelAbonniertAenderungenCell } from '@one/components/common/ArtikelAbonniertAenderungenCell'
import { ArtikelAbonniertBTypStatusSign } from '@one/components/common/ArtikelAbonniertBTypStatusSign'
import { ArtikelAbonniertStatusMedal } from '@one/components/common/ArtikelAbonniertStatusMedal'
import {
  ArtikelAbonniertStatusRGB,
  getArtikelAbonniertStatusRGB
} from '@one/components/common/ArtikelAbonniertStatusSymbol'
import { SyncEinstellungField } from '@one/components/common/SyncEinstellungField'
import { getArchiviertRowStyle } from '@one/components/common/TableUtils'
import { WarengruppeNameCell, WarengruppeNrCell } from '@one/components/common/WarengruppeCell'
import { formatLieferant } from '@one/components/common/formatters'
import { useArSSTCache } from '@one/datacaches/useArSSTCache'
import { useArtikelMapperEinstellungenCache } from '@one/datacaches/useArtikelMapperEinstellungenCache'
import { HkmEnum } from '@one/enums/HkmEnum'
import {
  AboArtikelEanGruppeStatus,
  ArtikelBetriebstyp,
  ArtikelDisplayJson,
  ArtikelSelektionDisplayJson,
  BetriebstypJson,
  EanZuordnung,
  EkKonditionenDefinitionJson,
  LieferantDisplayJson,
  SparteDisplayJson,
  WarengruppeDisplayJson
} from '@one/typings/apiTypings'
import { useEnums } from '@utils/enums'
import { ModelAction, SilentUpd, SilentUpdTarget } from '@utils/modelmgr'
import { useQuery } from '@utils/routing'
import { useStateEx } from '@utils/stateex'
import { AppContext } from '@utils/ui/App/AppContext'
import { Button } from '@utils/ui/Buttons/Button'
import { ColorButton } from '@utils/ui/Buttons/ColorButton'
import { IconButton } from '@utils/ui/Buttons/IconButton'
import { MenuButton, MenuButtonItem } from '@utils/ui/Buttons/MenuButton'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard, DataTableFilterRef } from '@utils/ui/DataTable/DataTableCard'
import { AddColumnToIndex } from '@utils/ui/DataTable/DataTableColumnUtils'
import {
  AdditionalFilterConfig,
  useAdditionalFilter
} from '@utils/ui/DataTable/filter/AdditionalFilterMenu'
import { useDialogAnker } from '@utils/ui/DialogAnker'
import { Permission } from '@utils/ui/Permission'
import { ThemeContext } from '@utils/ui/Theme'
import { TooltipEx, TooltipWrapper } from '@utils/ui/TooltipWrapper'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Medal } from '@utils/ui/fields/Medal'
import { Switch } from '@utils/ui/fields/Switch'
import { arrayFromSet, singleFromSet } from '@utils/utils'
import { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ArtikelAboSelektionDefaultsDialog } from './ArtikelSelektionDefaultsDialog'
import { ArtikelAboSelektionDetails } from './ArtikelSelektionDetails'
import { DiffSelectField } from './DiffSelectField'
import { useMultiArtikelDetailsState as useDiffModusResolver } from './MultiArtikelDetailsState'
import {
  ArtikelAbonniertStatusEx,
  ArtikelAbonniertStatusExList,
  ArtikelSelektionEdit,
  ArtikelSelektionEintragChangeEdit,
  ArtikelSelektionEintragEdit
} from './model/ArtikelSelektionTypes'
import {
  ArtikelAboSelektionUsecase,
  getStatusSort,
  isArtikelSelektionEintragChanged
} from './model/ArtikelSelektionUsecase'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { formatNumber } from '@utils/numberutils'
import { getMessageIDTitle, MessageID } from '@one/MessageIDs'

export interface ArtikelAboSelektionArtikelTableProps {
  dispatch: (changes: ModelAction) => void
  silentUpd: SilentUpd<ArtikelSelektionEdit>
  artikelSelektionArSSTId: number
  artikelSelektionId: number
  artikelSelektionenMap: Map<number, ArtikelSelektionDisplayJson>
  ergebnisse?: ArtikelSelektionEintragEdit[]
  ergebnisseChanges?: Map<number, ArtikelSelektionEintragChangeEdit>
  hideZentraleWg?: boolean
  aboBetriebsTyp: ArtikelBetriebstyp
  aboBetriebsTypModel: BetriebstypJson
  konditionDefinition?: EkKonditionenDefinitionJson
  aenderungenIdx: Map<number, number>
  filterMode: boolean
  warengruppen: WarengruppeDisplayJson[]
  sparten: SparteDisplayJson[]
  warengruppenGlobal: WarengruppeDisplayJson[]
  spartenGlobal: SparteDisplayJson[]
  zubestaetigenHints: Map<number, string>
  lieferant?: LieferantDisplayJson
  wgSparteAuswahlManuell?: boolean
  forEinzelhandel?: boolean
}

export const ArtikelAboSelektionArtikelTable = ({
  dispatch,
  silentUpd,
  artikelSelektionArSSTId,
  artikelSelektionId,
  artikelSelektionenMap,
  ergebnisse = [],
  ergebnisseChanges = new Map(),
  hideZentraleWg = true,
  aboBetriebsTyp,
  aboBetriebsTypModel,
  konditionDefinition,
  aenderungenIdx,
  filterMode,
  warengruppen,
  sparten,
  warengruppenGlobal,
  spartenGlobal,
  zubestaetigenHints,
  lieferant,
  wgSparteAuswahlManuell,
  forEinzelhandel
}: ArtikelAboSelektionArtikelTableProps) => {
  const { et } = useEnums()
  const { isGesellschafter, isAllianz, isWithoutErp } = useContext(AppContext)
  const { openLabelsEdit } = useArtikelLabelEdit()
  const [DlgAnker, dlgShow] = useDialogAnker()
  const [query] = useQuery()
  const { darkMode } = useContext(ThemeContext)

  const [activeStatusFilter, setActiveStatusFilter] = useState<string>()

  const artikelMapperEinstellungenCache = useArtikelMapperEinstellungenCache()

  const [diffArtikelMap, setDiffArtikelMap] = useState<Map<number, Set<number>>>()

  const arrstCache = useArSSTCache()

  const [selected, setSelected, getSelected] = useStateEx<Set<ArtikelSelektionEintragEdit>>(
    new Set()
  )

  const [akzeptiereBT, setAkzeptiereBT] = useState(false)

  const [diffModus, setDiffModus] = useState<number[]>()

  const [eintragAenderungen, eintragAenderungenLoading] = useDiffModusResolver(
    artikelSelektionId,
    diffModus
  )

  const tableRef = useRef<DataTableFilterRef>()

  const { askToConfirm } = useMessageDialog()

  const countAenderungen = useCallback(
    (row: ArtikelSelektionEintragEdit) => {
      let count = 0
      row.aenderungenIds?.forEach((k) => {
        if (Array.from(diffArtikelMap?.get(k) ?? []).find((a) => a === row.id)) {
          count++
        }
      })
      return count
    },
    [diffArtikelMap]
  )

  const singleDispatch = useCallback(
    (status: ArtikelAbonniertStatusEx, eintrag) => {
      dispatch({
        type: ArtikelAboSelektionUsecase.SETEINTRAGSTATUS,
        selected: [eintrag],
        status
      })
    },
    [dispatch]
  )

  const toggleDiffArtikelMap = useCallback(
    (aenderungenIds: number[], row: ArtikelSelektionEintragEdit) => {
      let newItems = new Set<number>([row.id])
      if (selected.has(row)) {
        newItems = new Set(Array.from(selected).map((s) => s.id))
      }
      const firstCurrentAenderung = row.aenderungenIds.find((id) => aenderungenIds.includes(id))
      const shouldRemove = diffArtikelMap?.get(firstCurrentAenderung)?.has(row.id)

      setDiffArtikelMap((prev) => {
        const newMap = new Map(prev)
        aenderungenIds.forEach((id) => {
          let list = newMap.get(id)
          if (list === undefined) {
            newMap.set(id, newItems)
          } else {
            // gesamte Selektion entweder hinzufügen oder entfernen
            newItems.forEach(shouldRemove ? list.delete : list.add, list)
            newMap.set(id, list)
          }
        })
        return newMap
      })
      const aenderungenCount = countAenderungen(row)
      if (row.aenderungenIds?.length > 0 && row.aenderungenIds?.length === aenderungenCount) {
        singleDispatch(ArtikelAbonniertStatusEx.EINGESCHL, row)
      } else if (row.aenderungenIds?.length > 0) {
        singleDispatch(ArtikelAbonniertStatusEx.UNBESTIMMT, row)
      }
    },
    [selected, diffArtikelMap, countAenderungen, singleDispatch]
  )

  const handleStatusDblClick = useCallback(
    (row: ArtikelSelektionEintragEdit) => () => {
      const sel = ergebnisseChanges.get(row.id)
      const hsId = sel?.hauptSelektionId || row.hauptSelektionId
      if ((hsId != null && hsId !== artikelSelektionId) || row.eigenlistung) {
        return null
      }
      if (isAllianz) {
        if (row.statusEx !== ArtikelAbonniertStatusEx.EINGESCHL) {
          const nextStatus = () => {
            switch (sel?.statusEx || row.statusEx) {
              case ArtikelAbonniertStatusEx.NEU:
              case ArtikelAbonniertStatusEx.UNTERBROCHEN:
              case ArtikelAbonniertStatusEx.UNBESTIMMT:
                return ArtikelAbonniertStatusEx.EINGESCHL

              case ArtikelAbonniertStatusEx.GEAENDERT:
              case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
              case ArtikelAbonniertStatusEx.AUSGELISTET:
                return ArtikelAbonniertStatusEx.BESTAETIGT

              default:
                return row.statusEx
            }
          }
          dispatch({
            type: ArtikelAboSelektionUsecase.SETEINTRAGSTATUS,
            selected: getSelected(),
            status: nextStatus()
          })
        }
      } else {
        dispatch({
          type: ArtikelAboSelektionUsecase.TOGGLEEINTRAGSTATUS,
          selected: getSelected(),
          row
        })
      }
    },
    [artikelSelektionId, dispatch, ergebnisseChanges, getSelected, isAllianz]
  )

  const afc = useMemo<AdditionalFilterConfig>(
    () =>
      ({
        label: 'archivierte',
        name: 'archivierte',
        onFilter: (row) =>
          row.artikel.archiviert &&
          !(
            row.status != ArtikelAbonniertStatusEx.AUSGESCHL ||
            ergebnisseChanges.get(row.id) == null
          )
      }) as AdditionalFilterConfig,
    [ergebnisseChanges]
  )

  const [ergebnisseFilteredStep1, additionalFilter] = useAdditionalFilter(ergebnisse, afc)

  const ergebnisseFiltered = useMemo(() => {
    if (diffModus?.length >= 1) {
      const aenderungenIdx = new Set(diffModus)
      return ergebnisseFilteredStep1.filter((row) =>
        row.aenderungenIds?.find((k) => aenderungenIdx.has(k))
      )
    }
    return ergebnisseFilteredStep1
  }, [ergebnisseFilteredStep1, diffModus])

  const { betriebsTypColNeeded, eanGruppeStatusColNeeded, eanMehrdeutigColNeeded } = useMemo(
    () => ({
      betriebsTypColNeeded:
        ergebnisseFiltered != null &&
        ergebnisseFiltered.find(
          (row) => row.betriebsTypErmittelt != null && row.betriebsTypErmittelt !== aboBetriebsTyp
        ) != null,
      eanGruppeStatusColNeeded:
        ergebnisseFiltered != null && ergebnisseFiltered.find((row) => row.eanGruppeExtra) != null,
      eanMehrdeutigColNeeded: ergebnisseFiltered.find((row) => row.abgleichMehrdeutigErp) != null
    }),
    [aboBetriebsTyp, ergebnisseFiltered]
  )

  const columnProps = useMemo<ArtikelDefaultColumnsProps<ArtikelSelektionEintragEdit>>(() => {
    const getHauptselektion = (row: ArtikelSelektionEintragEdit) => {
      const hauptSelektionId =
        ergebnisseChanges.get(row.id)?.hauptSelektionId || row.hauptSelektionId
      if (hauptSelektionId == null || hauptSelektionId === artikelSelektionId) {
        return null
      }
      // if (row.status === ArtikelAbonniertStatusEx.AUSGESCHL) {
      //   return null
      // }
      return (
        artikelSelektionenMap.get(row.hauptSelektionId) ||
        ({ aboName: 'unbekannt' } as ArtikelSelektionDisplayJson)
      )
    }
    let addColumnToIndex = [
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'id',
          header: <Circle style={{ fontSize: '12px' }} />,
          headerTip: 'Eintrag geändert?',
          align: 'center',
          body: (row) => {
            const val = isArtikelSelektionEintragChanged(ergebnisseChanges.get(row.id))
            return val && <Circle style={{ fontSize: '12px', color: orange[500] }} />
          },
          valueGetter: (row) =>
            isArtikelSelektionEintragChanged(ergebnisseChanges.get(row.id))
              ? 'geändert'
              : 'ungeändert'
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'status',
          header: 'Status',
          align: 'center',
          // tooltip: (row) => row.erpAusgelistet && 'Bei diesem Lieferanten im ERP ausgelistet!',
          body: (row) => {
            const value = ergebnisseChanges.get(row.id)?.statusEx || row.statusEx
            const disabled =
              row.eigenlistung ||
              (row.artikel.archiviert && row.statusEx === ArtikelAbonniertStatusEx.AUSGELISTET) ||
              (row.betriebsTypUnbekannt &&
                row.betriebsTypErmittelt !== ArtikelBetriebstyp.UNBEKANNT &&
                row.betriebsTypErmittelt != null &&
                row.betriebsTypErmittelt !== aboBetriebsTyp)

            return (
              <ArtikelAbonniertStatusMedal
                value={value}
                oldValue={row.statusEx}
                et={et}
                darkMode={darkMode}
                onDblClick={handleStatusDblClick(row)}
                andereHS={getHauptselektion(row) != null}
                erpAusgelistent={row.erpAusgelistet}
                disabled={disabled}
              />
            )
          },
          sortGetter: (row) =>
            getStatusSort(ergebnisseChanges.get(row.id)?.statusEx || row.statusEx),

          valueGetter: (row) =>
            et(
              HkmEnum.ArtikelAbonniertStatusEx,
              ergebnisseChanges.get(row.id)?.statusEx || row.statusEx
            )
        }
      },
      isWithoutErp
        ? null
        : {
            beforeField: 'artikel.hageNummer',
            column: {
              field: 'erpAusgelistet',
              header: 'AUS',
              headerTip: 'Beim Lieferanten im ERP augelistet?',
              align: 'center',
              // hidden: keineAusgelisteten,
              body: (row) => {
                if (row.artikel.archiviert && !row.erpAusgelistet) {
                  return <CrisisAlert fontSize="small" color="warning" />
                }
                if (row.erpAusgelistet) {
                  if (row.artikel.archiviert) {
                    return <NotInterestedRounded fontSize="small" />
                  }
                  switch (row.statusEx) {
                    case ArtikelAbonniertStatusEx.AUSGELISTET: {
                      return <NotInterestedRounded fontSize="small" />
                    }
                    default: {
                      return <CrisisAlert fontSize="small" color="error" />
                    }
                  }
                }
                return null
              },
              valueGetter: (row) => {
                if (row.artikel.archiviert && !row.erpAusgelistet) {
                  return 'Artikel archiviert'
                }
                if (row.erpAusgelistet) {
                  if (row.artikel.archiviert) {
                    return 'Im ERP ausgelistet und archiviert'
                  }
                  switch (row.statusEx) {
                    case ArtikelAbonniertStatusEx.AUSGELISTET: {
                      return 'Im ERP ausgelistet'
                    }
                    default: {
                      return 'Im ERP ausgelistet aber Abo nicht beendet!'
                    }
                  }
                }
                return null
              },
              tooltip: (row) => {
                if (row.artikel.archiviert && !row.erpAusgelistet) {
                  return 'Artikel archiviert aber nicht ausgelistet'
                }
                if (row.erpAusgelistet) {
                  if (row.artikel.archiviert) {
                    return 'Artikel im ERP ausgelistet und archiviert'
                  }
                  switch (row.statusEx) {
                    case ArtikelAbonniertStatusEx.AUSGELISTET:
                      return 'Artikel im ERP und in der Listung ausgelistet'
                    case ArtikelAbonniertStatusEx.AUSGESCHL:
                      return 'Artikel im ERP ausgelistet'
                    default:
                      return 'Artikel im ERP ausgelistet aber noch abonniert'
                  }
                }
                return null
              }
            }
          },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'hauptSelektionId',
          header: 'HS',
          headerTip: 'Gehört zu dieser Selektion oder einer anderen?',
          align: 'center',
          body: (row) => {
            const value = getHauptselektion(row)
            if (value == null) {
              return null
            }
            const lieferant =
              value.lieferant?.nummer +
              ' ' +
              value.lieferant?.name1 +
              ' ' +
              (value.lieferant?.name2 ?? '')
            return (
              <IconButton
                size="small"
                icon={<KeyboardTab />}
                to={AppPaths.ArtikelAboSelektionPflegeFn(row.hauptSelektionId)}
                tooltip={
                  <span>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '80%' }}>
                      Klick um Selektion zu öffnen
                    </div>
                    <div style={{ height: '1em' }} />
                    Artikel ist enthalten in
                    <br />
                    <table style={{ tableLayout: 'fixed', textAlign: 'left' }}>
                      <tbody>
                        <tr>
                          <th>Abo:</th>
                          <td>{value.aboName}</td>
                        </tr>
                        <tr>
                          <th>Selektion:</th>
                          <td>{value.name}</td>
                        </tr>
                        <tr>
                          <th>Lieferant:</th>
                          <td>{lieferant}</td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                }
              />
            )
          },
          valueGetter: (row) => {
            const value = getHauptselektion(row)
            return value && value.aboName + '/' + value.name
          }
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'eanGruppeStatus',
          header: '[EAN]',
          headerTip: 'Ist Artikel in EAN-Gruppe der Selektion enthalten?',
          off: !eanGruppeStatusColNeeded,
          align: 'center',
          valueGetter: (row) => {
            const value = ergebnisseChanges.get(row.id)?.eanGruppeStatus || row.eanGruppeStatus
            return value === AboArtikelEanGruppeStatus.EINGESCHL ? 'Enthalten' : 'Nicht enthalten'
          },
          body: (row) => {
            const value = ergebnisseChanges.get(row.id)?.eanGruppeStatus || row.eanGruppeStatus
            return (
              (row.statusEx === ArtikelAbonniertStatusEx.EINGESCHL &&
                value === AboArtikelEanGruppeStatus.EINGESCHL && (
                  <TooltipEx arrow title="Ist in EANGruppe des Abos enthalten">
                    <Check color="success" fontSize="small" />
                  </TooltipEx>
                )) ||
              (row.statusEx === ArtikelAbonniertStatusEx.EINGESCHL &&
                value !== AboArtikelEanGruppeStatus.EINGESCHL && (
                  <TooltipEx arrow title="Ist nicht in EANGruppe des Abos enthalten">
                    <Clear color="error" fontSize="small" />
                  </TooltipEx>
                ))
            )
          }
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'messageIds',
          hideIfEmpty: true,
          header: 'FM',
          headerTip: 'Fehlermeldungen',
          align: 'center',
          valueGetter: (row) => row.messageIds?.map(getMessageIDTitle).filter(Boolean),
          body: (row) => {
            const messages = row.messageIds?.map(getMessageIDTitle).filter(Boolean) || []
            return messages?.length > 0 ? (
              <Medal
                text={formatNumber(messages.length)}
                tooltip={
                  <ul style={{ paddingLeft: 16, marginTop: 4 }}>
                    {messages.map((m) => (
                      <li key={m} style={{ paddingTop: 8 }}>
                        {m} <br />
                      </li>
                    ))}
                  </ul>
                }
              />
            ) : null
          }
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'abgleichMehrdeutig',
          header: 'MD',
          headerTip: 'Mehrdeutig, EAN verschiedenen Betriebstypen zugeordnet',
          off: !eanMehrdeutigColNeeded,
          align: 'center',
          valueGetter: (row) => (row.abgleichMehrdeutig ? 'EAN Mehrdeutig' : null),
          body: (row) => {
            return row.abgleichMehrdeutigErp ? (
              <TooltipEx arrow title="Mehrdeutig, EAN verschiedenen Betriebstypen zugeordnet">
                <ControlPointDuplicate color="error" fontSize="small" />
              </TooltipEx>
            ) : null
          }
        }
      },
      isWithoutErp
        ? null
        : {
            beforeField: 'artikel.hageNummer',
            column: {
              field: 'erpSync',
              header: 'ERP',
              align: 'center',
              body: (row) => {
                if (row.notValid) {
                  return (
                    <TooltipEx title="Aktualisierungsfehler" arrow>
                      <PriorityHighOutlined fontSize="small" />
                    </TooltipEx>
                  )
                }
                if (row.eigenlistung) {
                  return (
                    <TooltipEx title="Eigenlistungsartikel" arrow>
                      <Inventory fontSize="small" />
                    </TooltipEx>
                  )
                }
                if (
                  row.statusEx === ArtikelAbonniertStatusEx.UNTERBROCHEN ||
                  row.statusEx === ArtikelAbonniertStatusEx.KONFLIKT_DZ
                ) {
                  return (
                    <TooltipEx title="Konflikte" arrow>
                      <Warning fontSize="small" />
                    </TooltipEx>
                  )
                }
                if (row.statusEx === ArtikelAbonniertStatusEx.NEU) {
                  return (
                    <TooltipEx title="Neu, kann abonniert werden" arrow>
                      <NewReleases fontSize="small" />
                    </TooltipEx>
                  )
                }
                if (row.erpVorhanden) {
                  return (
                    <TooltipEx title="Im ERP vorhanden" arrow>
                      <Task fontSize="small" />
                    </TooltipEx>
                  )
                }
                if (row.abgleichMehrdeutigZentral) {
                  return (
                    <TooltipEx
                      arrow
                      title="Mehrdeutig, EAN verschiedenen Zentral-Artikeln zugeordnet!"
                    >
                      <ControlPointDuplicate color="error" fontSize="small" />
                    </TooltipEx>
                  )
                }
                return (
                  <TooltipEx title="Nicht im ERP vorhanden" arrow>
                    <Close fontSize="small" />
                  </TooltipEx>
                )
              },
              valueGetter: (row) => {
                if (row.notValid) {
                  return 'Aktualisierungsfehler'
                }
                if (row.eigenlistung) {
                  return 'Eigenlistungsartikel'
                }
                if (
                  row.statusEx === ArtikelAbonniertStatusEx.UNTERBROCHEN ||
                  row.statusEx === ArtikelAbonniertStatusEx.KONFLIKT_DZ
                ) {
                  return 'Konflikt durch ERP-Änderung'
                }
                if (row.abgleichMehrdeutigZentral) {
                  return 'Mehrdeutig, EAN verschiedenen Zentral-Artikeln zugeordnet!'
                }
                if (row.statusEx === ArtikelAbonniertStatusEx.NEU) {
                  return 'Nicht im ERP vorhanden'
                }
                if (row.erpVorhanden) {
                  return 'ERP Synchron'
                }
                return 'Neu'
              }
            }
          },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'betriebsTypErmittelt',
          header: (
            <div>
              BT<span style={{ fontSize: '76%', marginTop: -10 }}>erm.</span>
            </div>
          ),
          headerTip: 'Ermittelter Betriebstyp',
          off: !betriebsTypColNeeded,
          align: 'center',
          body: (row) => (
            <ArtikelAbonniertBTypStatusSign
              betriebsTypErmittelt={row.betriebsTypErmittelt}
              aboBetriebsTyp={aboBetriebsTyp}
              abonniert={row.statusEx === ArtikelAbonniertStatusEx.EINGESCHL}
            />
          ),
          valueGetter: (row) => {
            if (row.betriebsTypErmittelt === aboBetriebsTyp) {
              return 'Passt zum Abo'
            }
            if (
              row.betriebsTypErmittelt === null ||
              row.statusEx === ArtikelAbonniertStatusEx.EINGESCHL
            ) {
              return null
            }
            return row.betriebsTypErmittelt
          }
        }
      },
      // {
      //   beforeField: 'artikel.hageNummer',
      //   column: {
      //     field: 'hatPreisaenderung',
      //     header: 'EK',
      //     headerTip: 'Preisänderung',
      //     align: 'center',
      //     valueGetter: (row) => (row.hatPreisaenderung ? 'Preisänderung' : null),
      //     body: (row) =>
      //       row.hatPreisaenderung ? (
      //         <TooltipEx title="Geänderte Preise" arrow>
      //           <NewReleases fontSize="small" />
      //         </TooltipEx>
      //       ) : null
      //   }
      // },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'zubestaetigen',
          header: 'W',
          headerTip:
            'Geänderte Felder mit Warnungen. Evtl. müssen im ERP Vorkehrungen getroffen werden, bevor die Änderungen bestätigt werden können.',
          hideIfEmpty: true,
          align: 'center',
          valueGetter: (row) =>
            row.aenderungenIds
              ?.map((k) => zubestaetigenHints.get(k))
              .filter(Boolean)
              .map((s) => s.split('\n')[0]),
          body: (row) => {
            const messages = row.aenderungenIds
              ?.map((k) => zubestaetigenHints.get(k)?.split('\n'))
              .filter(Boolean)
            const chg = ergebnisseChanges.get(row.id)
            const attributWarnungenGeprueft = chg
              ? chg.attributWarnungenGeprueft
              : row.attributWarnungenGeprueft
            return messages?.length > 0 ? (
              <TooltipEx
                arrow
                title={
                  <ul style={{ paddingLeft: 16, marginTop: 4 }}>
                    {messages.map((m) => (
                      <li key={m[0]} style={{ paddingTop: 8 }}>
                        {m[0]} <br key={m[1]} /> {m[1]}
                      </li>
                    ))}
                  </ul>
                }
              >
                <CrisisAlert
                  fontSize="small"
                  color={attributWarnungenGeprueft ? 'warning' : 'error'}
                  style={{ paddingTop: 4 }}
                />
              </TooltipEx>
            ) : null
          }
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'aenderungenIds',
          header: 'Änderungen',
          align: 'center',
          body: (row) => (
            <ArtikelAbonniertAenderungenCell
              artikelMapperEinstellungen={artikelMapperEinstellungenCache.data}
              ids={row.aenderungenIds}
              konflikt={
                row.statusEx === ArtikelAbonniertStatusEx.UNTERBROCHEN ||
                row.statusEx === ArtikelAbonniertStatusEx.KONFLIKT_DZ
              }
            />
          ),
          valueGetter: (row) =>
            row.aenderungenIds
              ?.filter((k, i, a) => a.indexOf(k) === i)
              .map((k) => artikelMapperEinstellungenCache.data.find((a) => a.attributeId === k))
              .filter(Boolean)
              .map((i) => i.name)
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'zusatzAenderungenIds',
          header: 'Folgestufe',
          headerTip: 'Vorschau auf Änderungen mit ArSST der Folgestufe',
          align: 'center',
          hideIfEmpty: true,
          body: (row: ArtikelSelektionEintragEdit) => (
            <ArtikelAbonniertAenderungenCell
              artikelMapperEinstellungen={artikelMapperEinstellungenCache.data}
              ids={row.zusatzAenderungenIds}
              zusatz
            />
          ),
          valueGetter: (row: ArtikelSelektionEintragEdit) =>
            row?.zusatzAenderungenIds
              ?.filter((k, i, a) => a.indexOf(k) === i)
              .map((k) => artikelMapperEinstellungenCache.data.find((a) => a.attributeId === k))
              .filter(Boolean)
              .map((i) => i.name)
        }
      },
      {
        beforeField: 'artikel.hageNummer',
        column: {
          field: 'arsstId',
          header: 'ArSST',
          headerTip: 'Abweichende ArSST',
          align: 'left',
          off: forEinzelhandel,
          body: (row) => {
            if (!row.notValid && row.arsstId == null) {
              return null
            }
            const chg = ergebnisseChanges.get(row.id)
            const val = chg == undefined ? row.arsstId : chg?.arsstId
            const hs = getHauptselektion(row)
            if (hs != null) {
              if (val == null) {
                return null
              }
              return arrstCache.get(val).name
            }
            return (
              <SyncEinstellungField
                size="table"
                options={arrstCache.data}
                value={val}
                onChange={(e) =>
                  dispatch({
                    type: ArtikelAboSelektionUsecase.SETARSST,
                    value: e.target.value,
                    row
                  })
                }
                emptyText="Wie Selektion"
                optionDisabled={(opt: ArtikelSelektionEintragEdit) =>
                  opt.id === artikelSelektionArSSTId
                }
              />
            )
          },
          valueGetter: (row) => {
            if (!row.notValid && row.arsstId == null) {
              return null
            }
            const chg = ergebnisseChanges.get(row.id)
            const arsstId = chg == undefined ? row.arsstId : chg?.arsstId
            return arsstId && arrstCache.get(arsstId)?.name
          }
        }
      },
      {
        beforeField: 'artikel.hauptlieferant',
        column: {
          field: 'artikel.hageWarengruppe.name',
          header: 'Zentrale-WG-Name',
          hidden: hideZentraleWg,
          body: (row) => <WarengruppeNameCell warengruppe={row.artikel.hageWarengruppe} />,
          decorator: (row, body) => artikelColumnDecorator(row.artikel, body)
        }
      },
      {
        beforeField: 'artikel.hageWarengruppe.name',
        column: {
          field: 'artikel.hageWarengruppe.nr',
          header: 'Zentrale-WG-Nr',
          align: 'right',
          hidden: hideZentraleWg,
          body: (row) => <WarengruppeNrCell warengruppe={row.artikel.hageWarengruppe} />,
          decorator: (row, body) => artikelColumnDecorator(row.artikel, body)
        }
      },
      {
        beforeField: 'artikel.industrieArtikelNummer',
        column: {
          field: 'lieferantIAN',
          header: 'IAN',
          headerTip: `Industrieartikelnummer des Lieferanten ${formatLieferant(lieferant)}`,
          hideIfEmpty: true,
          valueGetter: (row) => row.artikel?.lieferantIAN
        }
      }
    ] as AddColumnToIndex<ArtikelSelektionEintragEdit>[]

    addColumnToIndex = addColumnToIndex.filter(Boolean)

    // TODO: toReversed nutzen, sobald typescript 5 im Projekt
    const diffModusClone = [...(diffModus ?? [])]

    diffModusClone.reverse().forEach((km) => {
      const attr = artikelMapperEinstellungenCache.data.find((a) => a.attributeId === km)
      if (attr) {
        addColumnToIndex.push(
          {
            afterField: 'aenderungenIds',
            column: {
              wrapMode: 'pre',
              header: 'ERP: ' + attr.name,
              field: attr.name + '-new',
              align: 'center',
              valueGetter: (row) => {
                if (eintragAenderungen == null) {
                  return null
                }
                const changeItems = eintragAenderungen[row.id]
                const changeItem = changeItems && changeItems[km]
                return changeItem?.newString
              },
              body: (row) => {
                if (eintragAenderungen == null) {
                  return null
                }
                const changeItems = eintragAenderungen[row.id]
                const changeItem = changeItems && changeItems[km]
                const content = changeItem?.newString
                if (changeItem?.path) {
                  return (
                    <>
                      <span>{content}</span>
                      <div style={{ fontSize: '80%', marginTop: -2 }}>{changeItem?.path}</div>
                    </>
                  )
                }
                return <span>{content}</span>
              }
            }
          },
          {
            afterField: 'aenderungenIds',
            column: {
              wrapMode: 'pre',
              header: 'LST: ' + attr.name,
              field: attr.name + '-old',
              align: 'center',
              valueGetter: (row) => {
                if (eintragAenderungen == null) {
                  return null
                }
                const x = eintragAenderungen[row.id]
                const y = x && x[km]
                return y?.oldString
              },
              body: (row) => {
                if (eintragAenderungen == null) {
                  return null
                }
                const changeItems = eintragAenderungen[row.id]
                const changeItem = changeItems && changeItems[km]
                const content = changeItem?.oldString
                if (changeItem?.path) {
                  return (
                    <>
                      <span>{content}</span>
                      <div style={{ fontSize: '80%', marginTop: -2 }}>{changeItem?.path}</div>
                    </>
                  )
                }
                return <span>{content}</span>
              }
            }
          }
        )
      }
    })

    if (diffModus?.length > 0) {
      addColumnToIndex.push({
        afterField: 'aenderungenIds',
        column: {
          wrapMode: 'pre',
          header: 'geprüft',
          headerTip: 'Anzahl der geprüften Felder',
          field: 'geloest',
          align: 'center',
          body: (row) => {
            let isChecked = true
            for (let km of row.aenderungenIds) {
              if (!Array.from(diffArtikelMap?.get(km) ?? []).find((a) => a === row.id)) {
                isChecked = false
                break
              }
            }
            const aenderungCount = countAenderungen(row)
            return (
              <TooltipWrapper title="Klick um aktuelle Felder als geprüft zu markieren" arrow>
                <Checkbox
                  icon={<Difference fontSize="small" />}
                  checkedIcon={<NoteAdd fontSize="small" />}
                  size="small"
                  style={{ marginTop: -16, marginBottom: -8 }}
                  inputProps={{ 'data-selbox': true }}
                  onChange={() => {
                    toggleDiffArtikelMap(diffModus, row)
                  }}
                  checked={isChecked}
                  label={<small>{`${aenderungCount} von ${row.aenderungenIds?.length}`}</small>}
                />
              </TooltipWrapper>
            )
          },
          valueGetter: (row) => {
            const konfliktCount = countAenderungen(row)

            switch (row.aenderungenIds?.length - konfliktCount) {
              case 0:
                return 'Abgeschlossen'
              case row.aenderungenIds?.length:
                return 'Ungelöst'
              default:
                return 'In Bearbeitung'
            }
          }
        }
      })
    }

    return {
      isGesellschafter: isGesellschafter,
      fieldWrapper: {
        wrapper: 'artikel'
      },
      addColumnToIndex: addColumnToIndex,
      removeDefaultsByField: ['betriebsTyp'],
      artikelColumnDecorator: artikelColumnDecorator
    }
  }, [
    isWithoutErp,
    eanGruppeStatusColNeeded,
    eanMehrdeutigColNeeded,
    betriebsTypColNeeded,
    forEinzelhandel,
    hideZentraleWg,
    lieferant,
    diffModus,
    isGesellschafter,
    ergebnisseChanges,
    artikelSelektionId,
    artikelSelektionenMap,
    aboBetriebsTyp,
    et,
    darkMode,
    handleStatusDblClick,
    zubestaetigenHints,
    artikelMapperEinstellungenCache.data,
    arrstCache,
    dispatch,
    artikelSelektionArSSTId,
    eintragAenderungen,
    countAenderungen,
    diffArtikelMap,
    toggleDiffArtikelMap
  ])

  const columns = useArtikelColumns(columnProps)

  const callDispatch = useCallback(
    (status: ArtikelAbonniertStatusEx) => {
      dispatch({
        type: ArtikelAboSelektionUsecase.SETEINTRAGSTATUS,
        selected: Array.from(getSelected()).filter(
          (eintrag) => (ergebnisseChanges.get(eintrag.id)?.statusEx ?? eintrag.statusEx) !== status
        ),
        status
      })
    },
    [dispatch, getSelected, ergebnisseChanges]
  )

  const getIncludedArtikelList = useCallback(
    (
      selection: ArtikelSelektionEintragEdit[],
      status: ArtikelAbonniertStatusEx
    ): [string[], ArtikelSelektionEintragEdit[]] => {
      const includedArtikelHageNrs = new Set(
        selection
          .map((e) => e.artikel?.displayArtikelListe)
          .filter(Boolean)
          .flat()
      )
      const includedArtikel = ergebnisse
        .filter((e) => includedArtikelHageNrs.delete(e.artikel.hageNummer))
        .filter((e) => (ergebnisseChanges.get(e.id)?.statusEx ?? e.statusEx) !== status)

      return [Array.from(includedArtikelHageNrs), includedArtikel]
    },
    [ergebnisse, ergebnisseChanges]
  )

  const onAbonnieren = useCallback(() => {
    const selected = Array.from(getSelected())

    const [fehlendeArtikel, additionalArtikel] = getIncludedArtikelList(
      selected,
      ArtikelAbonniertStatusEx.EINGESCHL
    )

    if (fehlendeArtikel.length === 0 && additionalArtikel.length === 0) {
      callDispatch(ArtikelAbonniertStatusEx.EINGESCHL)
      return
    }

    const displayCount = selected.filter((s) => s.artikel?.displayArtikelListe?.length > 0).length

    let message: ReactNode[] = []

    if (additionalArtikel.length > 0) {
      message.push(
        <span key="3">{`Möchten Sie die ${additionalArtikel.length} Unterartikel, die noch nicht abonniert sind, mit abonnieren?`}</span>
      )
      message.push(<br key="4" />)
    }

    if (fehlendeArtikel.length > 0) {
      message.push(<br key="5" />)
      message.push(
        <span key="6">
          Die Artikel zu folgenden Hagenummern fehlen in der Artikelselektion und können hier nicht
          abonniert werden:
        </span>
      )
      message.push(<br key="7" />)
      message.push(
        <span key="8" style={{ fontSize: '80%' }}>
          {fehlendeArtikel.join(', ')}
        </span>
      )
    }

    askToConfirm({
      title: `Auswahl umfasst ${displayCount} Display-Artikel`,
      message,
      maxWidth: 'lg',
      confirmLabel:
        additionalArtikel.length > 0 ? 'Ja, inklusive der Unterartikel abonnieren' : 'Abonnieren',
      noLabel: additionalArtikel.length > 0 ? 'Nein, nur die ausgewählten abonnieren' : undefined,
      onConfirm: () => {
        dispatch({
          type: ArtikelAboSelektionUsecase.SETEINTRAGSTATUS,
          status: ArtikelAbonniertStatusEx.EINGESCHL,
          selected: selected.concat(additionalArtikel)
        })
      },
      onNo: () => {
        callDispatch(ArtikelAbonniertStatusEx.EINGESCHL)
      }
    })
  }, [getSelected, getIncludedArtikelList, askToConfirm, callDispatch, dispatch])

  const onAboBeenden = useCallback(() => {
    const selected = Array.from(getSelected())

    const [fehlendeArtikel, additionalArtikel] = getIncludedArtikelList(
      selected,
      ArtikelAbonniertStatusEx.AUSGESCHL
    )

    if (fehlendeArtikel.length === 0 && additionalArtikel.length === 0) {
      callDispatch(ArtikelAbonniertStatusEx.AUSGESCHL)
      return
    }

    const displayCount = selected.filter((s) => s.artikel?.displayArtikelListe?.length > 0).length

    let message: ReactNode[] = []

    if (additionalArtikel.length > 0) {
      message.push(
        <span key="3">{`Möchten Sie das Abo für die ${additionalArtikel.length} Unterartikel mit beenden?`}</span>
      )
      message.push(<br key="4" />)
    }

    askToConfirm({
      title: `Auswahl umfasst ${displayCount} Display-Artikel`,
      message,
      maxWidth: 'lg',
      confirmLabel:
        additionalArtikel.length > 0
          ? 'Ja, das Abo auch für die Unterartikel beenden'
          : 'Nicht Abonnieren',
      noLabel:
        additionalArtikel.length > 0 ? 'Nein, nur für die ausgewählten das Abo beenden' : undefined,
      onConfirm: () => {
        dispatch({
          type: ArtikelAboSelektionUsecase.SETEINTRAGSTATUS,
          status: ArtikelAbonniertStatusEx.AUSGESCHL,
          selected: selected.concat(additionalArtikel)
        })
      },
      onNo: () => {
        callDispatch(ArtikelAbonniertStatusEx.AUSGESCHL)
      }
    })
  }, [askToConfirm, callDispatch, dispatch, getIncludedArtikelList, getSelected])

  const onBestaetigen = useCallback(() => {
    callDispatch(ArtikelAbonniertStatusEx.BESTAETIGT)
  }, [callDispatch])

  const onAboUebernehmen = useCallback(() => {
    dispatch({
      type: ArtikelAboSelektionUsecase.EINTRAGUEBERNEHMEN,
      selected: getSelected()
    })
  }, [dispatch, getSelected])

  const setWarnungGeprueft = useCallback(
    (toggle: boolean) => {
      dispatch({
        type: ArtikelAboSelektionUsecase.SETWARNUNGGEPRUEFT,
        selected: getSelected(),
        toggle
      })
    },
    [dispatch, getSelected]
  )

  const onAboUnbestimmt = useCallback(() => {
    callDispatch(ArtikelAbonniertStatusEx.UNBESTIMMT)
  }, [callDispatch])

  const onEanGruppeEinschliessen = useCallback(() => {
    dispatch({
      type: ArtikelAboSelektionUsecase.SETEANGRUPPESTATUS,
      selected: getSelected(),
      status: AboArtikelEanGruppeStatus.EINGESCHL
    })
  }, [dispatch, getSelected])

  const onEanGruppeAusschliessen = useCallback(() => {
    dispatch({
      type: ArtikelAboSelektionUsecase.SETEANGRUPPESTATUS,
      selected: getSelected(),
      status: AboArtikelEanGruppeStatus.AUSGESCHL
    })
  }, [dispatch, getSelected])

  const verbindungAlsNeuerArtikel = useCallback(() => {
    dispatch({
      type: ArtikelAboSelektionUsecase.VERBINDUNGALSNEUERARTIKEL,
      selected: singleFromSet(getSelected())
    })
  }, [dispatch, getSelected])

  const verbindungMitArtikel = useCallback(() => {
    const artikelVerbinden = (seArtikel: ArtikelDisplayJson) => {
      if (seArtikel) {
        dispatch({
          type: ArtikelAboSelektionUsecase.VERBINDUNGMITARTIKEL,
          selected: singleFromSet(getSelected()),
          seArtikelId: seArtikel.seArtikelId
        })
      }
    }
    dlgShow((open, onClose) => (
      <ArtikelSearchDialog
        open={open}
        onClose={onClose(artikelVerbinden)}
        defaultDezentral
        disableDezentral
        disableListung
        disableArchiviert
      />
    ))
  }, [dispatch, dlgShow, getSelected])

  const onDefaultsWaehlen = useCallback(() => {
    dlgShow((open, onClose) => (
      <ArtikelAboSelektionDefaultsDialog
        open={open}
        onClose={onClose()}
        setDefaults={(warengruppe, sparte) =>
          dispatch({
            type: ArtikelAboSelektionUsecase.SETDEFAULTS,
            selected: getSelected(),
            warengruppe,
            sparte
          })
        }
        warengruppen={warengruppen}
        sparten={sparten}
        warengruppenGlobal={warengruppenGlobal}
        spartenGlobal={spartenGlobal}
      />
    ))
  }, [dispatch, dlgShow, getSelected, sparten, spartenGlobal, warengruppen, warengruppenGlobal])

  const stat = useMemo(() => {
    const stat = ergebnisseFiltered
      .map((erg) => ergebnisseChanges.get(erg.id)?.statusEx || erg.statusEx)
      .filter((status) => status != null)
      .reduce((v, status) => {
        if (v[status] == null) {
          v[status] = 0
        }
        v[status] += 1
        return v
      }, {})

    return ArtikelAbonniertStatusExList.map((status) => ({
      value: status,
      count: stat[status] ?? 0,
      text: et(HkmEnum.ArtikelAbonniertStatusEx, status),
      color: getArtikelAbonniertStatusRGB(status)
    }))
  }, [ergebnisseChanges, ergebnisseFiltered, et])

  const statistics = useMemo(() => {
    const getIsSelected = (value: string) => activeStatusFilter === value

    const handleFilterToggle = (value: string) => {
      let isSelected = getIsSelected(value)
      if (isSelected) {
        setActiveStatusFilter(null)
      } else {
        setActiveStatusFilter(value)
      }
      tableRef.current.setColumnFilters([{ field: 'status', value: isSelected ? null : value }])
    }

    const getColorModifier = (value: string) => {
      if (getIsSelected(value)) {
        return 700
      }
      return 500
    }

    return (
      <Grid container spacing={1} padding={1}>
        {stat
          .filter((state) => state.count > 0)
          .map((state) => (
            <Grid key={state.value}>
              <Medal
                variant="square"
                text={`${state.text}: ${state.count} `}
                backgroundColor={state.color[getColorModifier(state.text)]}
                color={getIsSelected(state.text) ? 'white' : 'black'}
                onClick={() => {
                  handleFilterToggle(state.text)
                }}
              />
            </Grid>
          ))}
      </Grid>
    )
  }, [stat, activeStatusFilter])

  const buttonStates = useMemo(() => {
    let hsCnt = 0
    let canUnbestimmtCnt = 0
    let bestaetigenCnt = 0
    let eanGruppeEingCnt = 0
    let eanGruppeAusgCnt = 0
    let newArtikelCnt = 0
    let ausgeschlossenCnt = 0
    let warnungPruefenCnt = 0
    let warnungPruefenCheckCnt = 0
    let betriebsTypOk = true
    let archiviert = false
    let messageIds = new Set<string>()

    selected.forEach((sel) => {
      const chg = ergebnisseChanges.get(sel.id)

      const hsId = chg ? chg.hauptSelektionId : sel.hauptSelektionId
      if (hsId != null && hsId !== artikelSelektionId) {
        hsCnt += 1
      }

      archiviert = archiviert || sel.artikel.archiviert

      if (
        sel.betriebsTypErmittelt != null &&
        sel.betriebsTypErmittelt !== aboBetriebsTyp &&
        sel.statusEx !== ArtikelAbonniertStatusEx.EINGESCHL
      ) {
        betriebsTypOk = false
      }

      const eanGruppeStatus = chg?.eanGruppeStatus || sel.eanGruppeStatus
      if (
        sel.eanGruppeExtra &&
        sel.statusEx === ArtikelAbonniertStatusEx.EINGESCHL &&
        eanGruppeStatus === AboArtikelEanGruppeStatus.EINGESCHL
      ) {
        eanGruppeEingCnt += 1
      }

      if (
        sel.eanGruppeExtra &&
        sel.statusEx === ArtikelAbonniertStatusEx.EINGESCHL &&
        eanGruppeStatus !== AboArtikelEanGruppeStatus.EINGESCHL
      ) {
        eanGruppeAusgCnt += 1
      }

      // const statusEx = chg?.statusEx || sel.statusEx

      if (sel.attributWarnungen) {
        warnungPruefenCnt += 1
        if (chg ? chg.attributWarnungenGeprueft : sel.attributWarnungenGeprueft) {
          warnungPruefenCheckCnt += 1
        }
      }

      switch (sel.statusEx) {
        case ArtikelAbonniertStatusEx.AUSGESCHL:
          ausgeschlossenCnt += 1
          break

        case ArtikelAbonniertStatusEx.KONFLIKT_DZ:
        case ArtikelAbonniertStatusEx.GEAENDERT:
        case ArtikelAbonniertStatusEx.AUSLISTUNG:
          bestaetigenCnt += 1
          // canUnbestimmtCnt += 1
          break

        case ArtikelAbonniertStatusEx.NEU:
        case ArtikelAbonniertStatusEx.UNTERBROCHEN:
        case ArtikelAbonniertStatusEx.UNBESTIMMT:
          canUnbestimmtCnt += 1
          break
      }

      if (
        !sel.erpVorhanden &&
        (sel.artikel.warengruppe == null || sel.artikel.sparte == null) &&
        chg?.statusEx === ArtikelAbonniertStatusEx.EINGESCHL
      ) {
        newArtikelCnt += 1
      }

      if (sel.messageIds) {
        sel.messageIds.forEach((messageId) => {
          messageIds.add(messageId)
        })
      }
    })

    const needDefaults =
      wgSparteAuswahlManuell &&
      ergebnisseFiltered.find(
        (e) => (e.artikel.sparte == null || e.artikel.warengruppe == null) && !e.erpVorhanden
      ) != null

    if (betriebsTypOk && akzeptiereBT) {
      setAkzeptiereBT(false)
    }

    const isSelectedRows = selected.size > 0
    const okOderAkzeptiert = betriebsTypOk || akzeptiereBT
    const warnungPruefenChecked = warnungPruefenCheckCnt > 0
    const warnungPruefenOffen =
      warnungPruefenCnt > 0 && warnungPruefenCheckCnt !== warnungPruefenCnt

    return {
      canWarnungPruefen: isSelectedRows && warnungPruefenCnt > 0,

      canUebernehmen: !archiviert && isSelectedRows && hsCnt === selected.size,

      canAbonnieren:
        !archiviert &&
        isSelectedRows &&
        okOderAkzeptiert &&
        hsCnt === 0 &&
        bestaetigenCnt === 0 &&
        !warnungPruefenOffen,

      canDeabonnieren: isSelectedRows && hsCnt === 0, //  deabonnieren auch wenn etwas zu bestätigen ist (keine Prüfung auf bestaetigenCnt)

      canSpaeterEntscheiden:
        !archiviert && isSelectedRows && hsCnt === 0 && canUnbestimmtCnt === selected.size,

      canSwitch: !archiviert && !betriebsTypOk && hsCnt === 0,

      warnungPruefenChecked,
      warnungPruefenOffen,

      canEanEin:
        !archiviert &&
        isSelectedRows &&
        eanGruppeAusgCnt === selected.size &&
        ausgeschlossenCnt === 0,

      canEanAus:
        !archiviert &&
        isSelectedRows &&
        eanGruppeEingCnt === selected.size &&
        ausgeschlossenCnt === 0,

      needDefaults,

      canSetDefaults: isSelectedRows && hsCnt === 0 && newArtikelCnt === selected.size,

      canBestaetigen:
        isSelectedRows && hsCnt === 0 && bestaetigenCnt === selected.size && !warnungPruefenOffen,

      messageIds: arrayFromSet(messageIds)
    }
  }, [
    aboBetriebsTyp,
    akzeptiereBT,
    artikelSelektionId,
    ergebnisseChanges,
    ergebnisseFiltered,
    selected,
    wgSparteAuswahlManuell
  ])

  const actions = useMemo(() => {
    const extraActions = [
      {
        label: 'Verbindung mit anderem ERP-Artikel',
        onClick: verbindungMitArtikel,
        disabled: selected.size !== 1
      },
      {
        label: 'Verbindung als neuer ERP-Artikel',
        onClick: verbindungAlsNeuerArtikel,
        disabled: selected.size !== 1
      }
    ]

    const onMessageAction = ({
      messageId,
      checked,
      eanZuordnung,
      name
    }: {
      messageId: string
      checked: boolean
      eanZuordnung?: EanZuordnung
      name?: string
    }) =>
      dispatch({
        type: ArtikelAboSelektionUsecase.SETMESSAGEACTION_ALL,
        messageId,
        eintraege: arrayFromSet(selected).map((e) => ({
          eintragId: e.id,
          verbindungMitSeArtikelId: e.abgleichMehrdeutigVorschlagArtikelId
        })),
        eanZuordnung,
        checked,
        name
      })

    const buildMessageAction = (messageId: string) => {
      const result: MenuButtonItem[] = []
      const msgTitle = getMessageIDTitle(messageId)
      switch (messageId) {
        case MessageID.IDE_DZ_EAN_KONFLIKT:
          result.push({
            label: msgTitle
          })
          result.push({
            label: 'EAN übernehmen und abonnieren',
            onClick: () =>
              onMessageAction({ messageId, checked: true, eanZuordnung: EanZuordnung.ZUGEORDNET })
          })
          result.push({
            label: 'EAN NICHT übernehmen und abonnieren',
            onClick: () =>
              onMessageAction({ messageId, checked: true, eanZuordnung: EanZuordnung.IGNORIERT })
          })
          result.push({
            label: 'verwerfen',
            onClick: () => onMessageAction({ messageId, checked: false })
          })
          result.push({
            label: 'divider'
          })
          break

        case MessageID.IDE_DZ_BT_ANDERER_ERMITTELT:
          result.push({
            label: msgTitle
          })
          result.push({
            label: 'In Betriebstyp des Abos übernehmen und abonnieren',
            onClick: () => onMessageAction({ messageId, checked: true })
          })
          result.push({
            label: 'verwerfen',
            onClick: () => onMessageAction({ messageId, checked: false })
          })
          result.push({
            label: 'divider'
          })
          break

        case MessageID.IDE_DZ_ABGLEICH_ANDERE_EANGRUPPE:
          result.push({
            label: msgTitle
          })
          result.push({
            label: 'Artikel verbinden',
            onClick: () => onMessageAction({ messageId, checked: true })
          })
          result.push({
            label: 'verwerfen',
            onClick: () => onMessageAction({ messageId, checked: false })
          })
          result.push({
            label: 'divider'
          })
          break

        case MessageID.IDE_DZ_MEHRERE_WURZEL_MES:
          result.push({
            label: msgTitle
          })
          result.push({
            label: 'Fehlende ME im ERP Artikel ergänzen',
            onClick: () => onMessageAction({ messageId, checked: true, name: 'ergaenzeMEs' })
          })
          result.push({
            label: 'Fehlende ME nicht ins ERP übernehmen',
            onClick: () => {
              onMessageAction({ messageId, checked: true, name: 'ergaenzeMEs' })
              onMessageAction({ messageId, checked: true, name: 'verbergeMEs' })
            }
          })
          result.push({
            label: 'verwerfen',
            onClick: () => {
              onMessageAction({ messageId, checked: false, name: 'verbergeMEs' })
              onMessageAction({ messageId, checked: false, name: 'ergaenzeMEs' })
            }
          })
          result.push({
            label: 'divider'
          })
          break

        case MessageID.IDE_ZE_ABGLEICH_MEHRDEUTIG:
          result.push({
            label: msgTitle
          })
          result.push({
            label: 'Mehrdeutigen Listungsartikel zuordnen',
            onClick: () => onMessageAction({ messageId, checked: true })
          })
          result.push({
            label: 'verwerfen',
            onClick: () => onMessageAction({ messageId, checked: false })
          })
          result.push({
            label: 'divider'
          })
          break
      }
      return result
    }

    const messageActions = buttonStates.messageIds.flatMap(buildMessageAction)

    return [
      <MenuButton
        key="extra"
        items={extraActions}
        icon={<Menu />}
        label="Extra"
        variant="text"
        size="small"
      />,
      <MenuButton
        key="messageActions"
        items={messageActions}
        icon={<Menu />}
        label="Fehleroptionen"
        variant="outlined"
        size="small"
        hideIfEmpty
      />,
      buttonStates.canEanEin && (
        <Button
          key="eanEin"
          label="EANGruppe setzen"
          onClick={onEanGruppeEinschliessen}
          color="info"
          variant="contained"
          size="small"
        />
      ),
      buttonStates.canEanAus && (
        <Button
          key="eanAus"
          label="EANGruppe entfernen"
          onClick={onEanGruppeAusschliessen}
          color="info"
          variant="contained"
          size="small"
        />
      ),
      buttonStates.canSwitch && (
        <Switch
          key="bt"
          name="btakzeptieren"
          size="small"
          label="BT akzeptieren"
          title={`Betriebstyp '${aboBetriebsTypModel?.btNr} - ${aboBetriebsTypModel?.bezeichnung}' beim abonnieren setzen`}
          checked={akzeptiereBT}
          onChange={() => setAkzeptiereBT(!akzeptiereBT)}
          style={{ marginLeft: 0, marginRight: 0 }}
        />
      ),
      buttonStates.canWarnungPruefen && (
        <Switch
          key="warnungPruefen"
          name="warnungPruefen"
          size="small"
          label="Warnung geprüft"
          title="Warnungen der ausgewählten Artikel als geprüft markieren, damit betroffende Artikel abonniert bzw. die Änderung übernommen werden können."
          checked={buttonStates.warnungPruefenChecked}
          onChange={() => setWarnungGeprueft(!buttonStates.warnungPruefenChecked)}
          style={{ marginLeft: 0, marginRight: 0 }}
        />
      ),
      buttonStates.needDefaults && (
        <Button
          key="needDefaults"
          label="Defaults wählen..."
          onClick={onDefaultsWaehlen}
          color="info"
          variant="contained"
          size="small"
          disabled={!buttonStates.canSetDefaults}
        />
      ),
      buttonStates.canUebernehmen && (
        <Button
          key="uebernehme"
          label="Artikel übernehmen"
          disabled={!buttonStates.canUebernehmen}
          onClick={onAboUebernehmen}
          color="info"
          variant="contained"
          size="small"
        />
      ),
      <ColorButton
        key="besatetigen"
        label="Bestätigen"
        visible={buttonStates.canBestaetigen}
        onClick={onBestaetigen}
        color={ArtikelAbonniertStatusRGB.BESTAETIGT}
        variant="contained"
        size="small"
      />,
      <ColorButton
        key="abonniere"
        label="Abonnieren"
        visible={buttonStates.canAbonnieren}
        onClick={onAbonnieren}
        color={ArtikelAbonniertStatusRGB.EINGESCHL}
        variant="contained"
        size="small"
      />,
      !isAllianz && (
        <ColorButton
          key="beende"
          label="Nicht abonnieren"
          disabled={!buttonStates.canDeabonnieren}
          onClick={onAboBeenden}
          color={ArtikelAbonniertStatusRGB.AUSGESCHL}
          variant="contained"
          size="small"
        />
      ),
      !isAllianz && (
        <ColorButton
          key="spaeter"
          label="Später entscheiden"
          disabled={!buttonStates.canSpaeterEntscheiden}
          onClick={onAboUnbestimmt}
          color={ArtikelAbonniertStatusRGB.UNBESTIMMT}
          variant="contained"
          size="small"
        />
      )
    ]
  }, [
    verbindungMitArtikel,
    selected,
    verbindungAlsNeuerArtikel,
    buttonStates.messageIds,
    buttonStates.canEanEin,
    buttonStates.canEanAus,
    buttonStates.canSwitch,
    buttonStates.canWarnungPruefen,
    buttonStates.warnungPruefenChecked,
    buttonStates.needDefaults,
    buttonStates.canSetDefaults,
    buttonStates.canUebernehmen,
    buttonStates.canBestaetigen,
    buttonStates.canAbonnieren,
    buttonStates.canDeabonnieren,
    buttonStates.canSpaeterEntscheiden,
    onEanGruppeEinschliessen,
    onEanGruppeAusschliessen,
    aboBetriebsTypModel?.btNr,
    aboBetriebsTypModel?.bezeichnung,
    akzeptiereBT,
    onDefaultsWaehlen,
    onAboUebernehmen,
    onBestaetigen,
    onAbonnieren,
    isAllianz,
    onAboBeenden,
    onAboUnbestimmt,
    dispatch,
    setWarnungGeprueft
  ])

  const tableActions = useMemo(
    (): DataTableAction[] => [
      {
        icon: 'info',
        tooltip: 'Archivierter Artikel',
        handler: () => {},
        check: (row) => (row.archiviert ? Permission.ENABLED : Permission.HIDDEN)
      },
      {
        icon: 'arrow_forward',
        tooltip: 'Artikel öffnen',
        getLink: (data: ArtikelSelektionEintragEdit) => AppPaths.ArtikelFn(data.artikel?.id)
      }
    ],
    []
  )

  const selectedRow = useMemo(() => singleFromSet(selected), [selected])
  const showDetailsEintragId = useMemo(
    () =>
      selectedRow &&
      (selectedRow?.hauptSelektionId == null ||
        selectedRow?.hauptSelektionId == artikelSelektionId) &&
      // selectedRow.erpVorhanden &&
      (selectedRow.aenderung ||
        selectedRow.notValid ||
        selectedRow.aenderungenIds?.length > 0 ||
        selectedRow.zusatzAenderungenIds?.length > 0) // || selectedRow?.hatPreisaenderung
        ? selectedRow.id
        : null,
    [artikelSelektionId, selectedRow]
  )

  const openLabelDialog = useCallback(() => {
    openLabelsEdit({
      artikel: Array.from(getSelected()).map((select) => select.artikel),
      callback: {
        then: ({ data, isAdded }) =>
          silentUpd(SilentUpdTarget.EDT, (model) => {
            const ergebnisse = model.ergebnisse.map((ergebniss) => {
              if (data.artikelIds.includes(ergebniss.artikel.id)) {
                // Set-zu-Array-Umwandlung, um Duplikate zu vermeiden
                let labels = Array.from(
                  new Set([...(ergebniss.artikel.labels ?? []), ...data.labelIds])
                )
                if (!isAdded) {
                  labels = labels.filter((labelId: number) => !data.labelIds.includes(labelId))
                }
                return {
                  ...ergebniss,
                  artikel: { ...ergebniss.artikel, labels }
                }
              }
              return ergebniss
            })
            return { ...model, ergebnisse }
          })
      }
    })
  }, [openLabelsEdit, getSelected, silentUpd])

  const konfliktFilter = useMemo(
    () => (
      <DiffSelectField
        artikelMapperEinstellungen={artikelMapperEinstellungenCache.data}
        aenderungenIdx={aenderungenIdx}
        value={diffModus}
        onChange={setDiffModus}
      />
    ),
    [artikelMapperEinstellungenCache.data, aenderungenIdx, diffModus]
  )

  const topActions = useMemo(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Labels bearbeiten',
        icon: 'label',
        onClick: openLabelDialog
      }
    ],
    [openLabelDialog]
  )

  const getRowStyle = useCallback((row: any) => getArchiviertRowStyle(row?.artikel?.archiviert), [])

  return (
    <>
      <Grid container direction="row" height="100%" spacing={1} wrap="nowrap">
        <Grid flexGrow={1}>
          <DataTableCard
            name="ArtikelAboSelektionTable"
            filterMode="both"
            columns={columns}
            actions={tableActions}
            topActions={topActions}
            value={ergebnisseFiltered}
            dense
            selectMode="multi"
            selectCol
            paging
            selected={selected}
            onSelect={setSelected}
            title="Artikel der Selektion"
            bottomActions={actions}
            bottomLeft={statistics}
            rowStyle={getRowStyle}
            additionalFilter={additionalFilter}
            localStateName="ArtikelAboSelektionTable"
            globalFilterInit={query?.btNummer}
            header={konfliktFilter}
            loading={eintragAenderungenLoading}
            ref={tableRef}
            emptyMessage={
              filterMode
                ? 'Für die aktuelle Filterung gibt es keine Artikel'
                : 'Keine Artikel in der Selektion'
            }
          />
        </Grid>
        {diffModus == null && showDetailsEintragId != null && (
          <Grid height="calc( 100% + 8px )" size={{ xs: 6, sm: 5, md: 4, lg: 3 }}>
            <ArtikelAboSelektionDetails
              konditionDefinition={konditionDefinition}
              eintragId={showDetailsEintragId}
              resetToggle={ergebnisse}
              dispatch={dispatch}
              ergebnisseChanges={ergebnisseChanges}
              zubestaetigenHints={zubestaetigenHints}
            />
          </Grid>
        )}
      </Grid>
      <DlgAnker />
    </>
  )
}
