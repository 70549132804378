/* eslint-disable no-plusplus */
import {InfoOutlined} from '@mui/icons-material'
import {Grid} from '@mui/material'
import {AppPaths} from '@one/AppPaths'
import {UserRoles} from '@one/UserRoles'
import {api} from '@one/api'
import {LieferantField} from '@one/components/Lieferant/LieferantField'
import {ArtikelBetriebstypField} from '@one/components/common/ArtikelBetriebstypField'
import {SyncEinstellungField} from '@one/components/common/SyncEinstellungField'
import {ArtikelAboJson, EANGruppeJson, LieferantDisplayJson} from '@one/typings/apiTypings'
import {useApiCaller} from '@utils/apicaller'
import {ErrorsType} from '@utils/modelmgr'
import {Action} from '@utils/ui/Action'
import {AppContext} from '@utils/ui/App/AppContext'
import {IconButton} from '@utils/ui/Buttons/IconButton'
import {CardEx} from '@utils/ui/CardEx'
import {AutocompleteEx} from '@utils/ui/fields/AutocompleteEx'
import {TextField} from '@utils/ui/fields/TextField'
import {dataFromEvent} from '@utils/utils'
import {useContext, useEffect, useMemo, useState} from 'react'

export interface ArtikelAboFormProps {
  model: ArtikelAboJson
  supportsEanGruppen: boolean
  errors: ErrorsType
  onChange: any
  isNew: boolean
  updModel: any
  lieferantSyncEinstList: any
  eanGruppen: EANGruppeJson[]
}

export const ArtikelAboForm = ({
  model,
  supportsEanGruppen,
  errors,
  onChange,
  isNew,
  updModel,
  lieferantSyncEinstList,
  eanGruppen
}: ArtikelAboFormProps) => {
  const [lastLieferantERPSugg, setLastLieferantERPSugg] = useState<LieferantDisplayJson>()
  const [defaultERPSugg, setDefaultERPSugg] = useState<any>()
  const { isAllianz, isPIMModel } = useContext(AppContext)
  const [apiCall] = useApiCaller(api)

  const onLieferantChange = (e) => {
    const { name, value } = dataFromEvent(e)
    if (name) {
      updModel({ [name]: value, name: value?.name1 })
    }
  }

  useEffect(() => {
    if (isNew && lastLieferantERPSugg !== model.lieferant) {
      setLastLieferantERPSugg(model.lieferant)
      if (model.lieferant == null) {
        setDefaultERPSugg(null)
      } else {
        apiCall<any>({
          method: 'GET',
          rest: 'lieferant/findMatchingDzLieferant',
          params: { zeLieferantId: model.lieferant.id },
          onSuccess: (data) => {
            setDefaultERPSugg(data.matches)
            if (data.connectedLieferant) {
              updModel((m: any) => ({
                ...m,
                dzLieferant: data.connectedLieferant,
                dzLieferantConnected: true
              }))
            } else if (data.matches.length === 1) {
              updModel((m: any) => ({
                ...m,
                dzLieferant: data.matches[0].lieferant,
                dzLieferantConnected: false
              }))
            } else {
              updModel((m: any) => ({
                ...m,
                dzLieferant: null,
                dzLieferantConnected: false
              }))
            }
          }
        })
      }
    }
  }, [apiCall, isNew, lastLieferantERPSugg, model.lieferant, onChange, updModel])

  const actions = useMemo<Action[]>(
    () => [
      {
        role: UserRoles.STAMMDATEN_EDITOR,
        tooltip: 'Hauptlieferanten Pflege',
        navlink: AppPaths.HauptlieferantPflegeFn(model.artikelListeId),
        text: 'Hauptlieferanten-Pflege',
        variant: 'outlined',
        visible: Boolean(model.id) && !isAllianz
      }
    ],
    [model, isAllianz]
  )

  const EanGruppeStandorte = useMemo(() => {
    const eanGruppe = eanGruppen?.find((eanGruppe) => eanGruppe.id === model.eanGruppeId)

    return eanGruppe?.standorte?.length > 0 ? (
      <IconButton
        icon={<InfoOutlined />}
        tooltipPlacement="top-start"
        tooltip={eanGruppe.standorte.map((standort) => (
          <span key={standort.id}>
            {standort.nr}: {standort.name}
            <br />
          </span>
        ))}
      />
    ) : null
  }, [eanGruppen, model.eanGruppeId])

  return (
    <CardEx
      backLink
      overviewLink={AppPaths.ArtikelAboUebersicht}
      topActions={actions}
      title={isNew ? 'Artikelabo erstellen' : `Artikelabo ${model.name || ''}`}
    >
      <Grid container spacing={2} direction="row">
        <Grid item xs={6}>
          <TextField
            name="name"
            label="Name"
            error={errors.name}
            value={model.name}
            onChange={onChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Bemerkung"
            name="bemerkung"
            error={errors.bemerkung}
            value={model.bemerkung}
            onChange={onChange}
            maxRows={12}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <LieferantField
            name="lieferant"
            label="Lieferant Zentral"
            error={errors.lieferant}
            value={model.lieferant}
            onChange={onLieferantChange}
            disabled={!isNew}
            fullWidth
            modus="zentral"
            required
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <LieferantField
            name="dzLieferant"
            label="Lieferant ERP"
            error={errors.dzLieferant}
            value={model.dzLieferant}
            defaultSugg={defaultERPSugg}
            onChange={onChange}
            disabled={!isNew}
            fullWidth
            modus="dezentral"
          />
        </Grid>
        {!isPIMModel ? (
          <Grid item sm={12} md={6}>
            <ArtikelBetriebstypField
              label="Betriebstyp"
              value={model.bt}
              error={errors.bt}
              onChange={onChange}
              name="bt"
              disabled={!isNew}
              showArtikelzahlVonLieferantId={model.lieferant?.id}
              required
            />
          </Grid>
        ) : null}
        {supportsEanGruppen && (
          <Grid item sm={12} md={6}>
            <AutocompleteEx
              label="EAN-Gruppe"
              value={model.eanGruppeId}
              options={eanGruppen}
              onChange={onChange}
              error={errors.eanGruppeId}
              name="eanGruppeId"
              optionValue="id"
              optionLabel={(gruppe: EANGruppeJson) =>
                gruppe && gruppe.name + ' - ' + gruppe.bezeichnung
              }
              disabled={!isNew}
              required
              fullWidth
              endAdornments={EanGruppeStandorte}
            />
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <SyncEinstellungField
            label="LiSST Einstellung"
            error={errors.lieferantSyncEinstList}
            options={lieferantSyncEinstList}
            name="lieferantSyncEinstId"
            value={model.lieferantSyncEinstId}
            onChange={onChange}
            fullWidth
            required
            disabled={!isNew}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}
