import { Checkbox } from '@mui/material'
import { NumberCell } from '@one/components/common/NumberCell'
import {
  EkKonditionenRabattgruppenViewJson,
  StaffelgruppeDisplayJson
} from '@one/typings/apiTypings'
import { Column, DataTable } from '@utils/ui/DataTable/DataTable'
import { Radio } from '@utils/ui/fields/Radio'
import { stopPropagation } from '@utils/ui/uiutils'
import { nameOf } from '@utils/utils'
import { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { KondiValueCell } from './KondiValueCell'
import { RabattgruppeField } from './fields/RabattgruppeField'
import { RabattverwendungField } from './fields/RabattverwendungField'
import { StaffelgruppenField } from './fields/StaffelgruppenField'
import {
  Ansicht as EkPreisAnsicht,
  EkKonditionenDefinitionEx,
  EkKonditionenWertEdit
} from './model/EkPreiseTypes'
import { EkPreiseUsecase } from './model/EkPreiseUsecase' // eslint-disable-next-line no-unused-vars

const useStyles = makeStyles()(() => ({
  table: {
    '& td, & input, & span': {
      fontSize: '1rem'
    },
    '& td': {
      padding: '8px'
    },
    '& th': {
      // padding: '6px'
    }
  },
  checkbox: {
    margin: '-8px'
  },
  number: {
    textAlign: 'right'
  }
}))

export interface EkPreisKonditionTableProps {
  dispatch: (action: any) => void
  konditionen: EkKonditionenWertEdit[]
  kondiDefinition: EkKonditionenDefinitionEx
  ansicht: EkPreisAnsicht
  ansichtStaffelWert: number
  ekRabattgruppen?: EkKonditionenRabattgruppenViewJson[]
  getDataColor: (row: EkKonditionenWertEdit) => [string, string | undefined] | null
  staffelgruppen?: StaffelgruppeDisplayJson[]
  readonly?: boolean
  sonderpreisRabattierbar?: boolean
  artikelRabattgruppeId?: number
  allianzModus: boolean
  errors?: object
  allianzAllowZuschl: boolean
}

export const EkPreisKonditionTable = ({
  dispatch,
  konditionen,
  kondiDefinition,
  ansicht,
  ansichtStaffelWert,
  ekRabattgruppen = [],
  getDataColor,
  readonly = false,
  staffelgruppen,
  sonderpreisRabattierbar = false,
  artikelRabattgruppeId = null,
  allianzModus,
  errors,
  allianzAllowZuschl
}: EkPreisKonditionTableProps) => {
  const { classes } = useStyles()

  const standortMode = ansicht === 'STANDORT'

  const setKondiValue = useCallback(
    (row, field, value) => dispatch({ type: EkPreiseUsecase.SETKONDIVALUE, row, field, value }),
    [dispatch]
  )

  const columns = useMemo<Column<EkKonditionenWertEdit>[]>(
    () =>
      [
        {
          field: 'standortEk',
          sortable: false,
          header: kondiDefinition.standortEkLabel,
          headerTip: kondiDefinition.standortEkTip,
          // width: 'auto',
          align: 'center',
          body: (row) =>
            row.level == 0 ? (
              <Checkbox
                className={classes.checkbox}
                size="small"
                checked={readonly ? row.standortEkOrg : row.standortEk}
                disabled={readonly || row.readonly}
                onChange={(e) =>
                  dispatch({ type: EkPreiseUsecase.SETSTANDORTEK, row, checked: e.target.checked })
                }
                color={readonly || row.readonly ? 'secondary' : 'primary'}
              />
            ) : (
              <div style={{ fontSize: '80%' }}>Version {row.level}</div>
            )
        },

        standortMode
          ? {
              field: 'preisEbene.nr',
              sortable: false,
              header: kondiDefinition.standort1Label,
              headerTip: kondiDefinition.standort1Tip,
              width: '4rem',
              align: 'right'
            }
          : {
              field: 'staffelmenge',
              sortable: false,
              header: kondiDefinition.staffelmengeLabel,
              headerTip: kondiDefinition.staffelmengeTip,
              width: '4rem',
              type: 'number'
            },
        {
          field: 'preisebene.name',
          sortable: false,
          header: kondiDefinition.standort2Label,
          headerTip: kondiDefinition.standort2Tip,
          valueGetter: (row) => row.preisEbene?.alias ?? row.preisEbene?.name,
          off: !standortMode
        },
        {
          field: 'hauptstaffel',
          sortable: false,
          header: kondiDefinition.hauptstaffelLabel,
          headerTip: kondiDefinition.hauptstaffelTip,
          width: '1rem',
          align: 'center',
          visible: (row) => row.standortEk,
          off: standortMode,
          body: (row) => (
            <Radio
              checked={row.hauptstaffel}
              compact
              onChange={(e, value) =>
                dispatch({
                  type: EkPreiseUsecase.SETHAUPTSTAFFEL,
                  kondiEbeneId: row.standortId,
                  staffelmenge: row.staffelmenge,
                  value
                })
              }
              onClick={(e) => {
                if (row.hauptstaffel === true) {
                  stopPropagation(e)
                  dispatch({
                    type: EkPreiseUsecase.SETHAUPTSTAFFEL,
                    kondiEbeneId: row.standortId,
                    staffelmenge: row.staffelmenge,
                    value: false
                  })
                }
              }}
              disabled={readonly || row.level !== 0}
            />
          )
        },
        {
          field: 'listenpreis',
          sortable: false,
          header: kondiDefinition.listenpreisLabel,
          headerTip: kondiDefinition.listenpreisTip,
          width: '8rem',
          type: 'money2',
          visible: (row) => row.standortEk,
          body: (row, { column }) => (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setKondiValue}
              readonly={readonly || row.readonly || !row.standortEk}
            />
          )
        },
        {
          field: 'sonderpreis',
          sortable: false,
          header: kondiDefinition.sonderpreisLabel,
          headerTip: kondiDefinition.sonderpreisTip,
          align: 'right',
          width: '8rem',
          visible: (row) => row.standortEk,
          body: (row, { column }) => (
            <NumberCell
              model={row}
              field={column.field as string}
              onChange={setKondiValue}
              readonly={
                readonly ||
                row.readonly ||
                !row.standortEk ||
                (!sonderpreisRabattierbar &&
                  !!row.ebeneRabattgruppeId &&
                  !!row.effektiveRabattgruppeWerte)
              }
            />
          )
        },
        {
          field: 'zuschlAbs1',
          sortable: false,
          header: kondiDefinition.zuschlAbs1Label,
          headerTip: kondiDefinition.zuschlAbs1Tip,
          opacity: !kondiDefinition.zuschlAbs1 && 0.6,
          align: 'right',
          width: '8rem',
          off: allianzModus && !allianzAllowZuschl,
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'zuschlAbs2',
          sortable: false,
          header: kondiDefinition.zuschlAbs2Label,
          headerTip: kondiDefinition.zuschlAbs2Tip,
          opacity: !kondiDefinition.zuschlAbs2 && 0.6,
          align: 'right',
          width: '8rem',
          off: allianzModus && !allianzAllowZuschl,
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'rabattProz1',
          sortable: false,
          header: kondiDefinition.rabattProz1Label,
          headerTip: kondiDefinition.rabattProz1Tip,
          opacity: !kondiDefinition.rabattProz1 && 0.6,
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'rabattProz2',
          sortable: false,
          header: kondiDefinition.rabattProz2Label,
          headerTip: kondiDefinition.rabattProz2Tip,
          opacity: !kondiDefinition.rabattProz2 && 0.6,
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'rabattProz3',
          sortable: false,
          header: kondiDefinition.rabattProz3Label,
          headerTip: kondiDefinition.rabattProz3Tip,
          opacity: !kondiDefinition.rabattProz3 && 0.6,
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'rabattProz4',
          sortable: false,
          header: kondiDefinition.rabattProz4Label,
          headerTip: kondiDefinition.rabattProz4Tip,
          opacity: !kondiDefinition.rabattProz4 && 0.6,
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'rabattProz5',
          sortable: false,
          header: kondiDefinition.rabattProz5Label,
          headerTip: kondiDefinition.rabattProz5Tip,
          opacity: !kondiDefinition.rabattProz5 && 0.6,
          align: 'right',
          width: '8rem',
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'zuschlAbs3',
          sortable: false,
          header: kondiDefinition.zuschlAbs3Label,
          headerTip: kondiDefinition.zuschlAbs3Tip,
          opacity: !kondiDefinition.zuschlAbs3 && 0.6,
          align: 'right',
          width: '8rem',
          off: allianzModus && !allianzAllowZuschl,
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'zuschlAbs4',
          sortable: false,
          header: kondiDefinition.zuschlAbs4Label,
          headerTip: kondiDefinition.zuschlAbs4Tip,
          opacity: !kondiDefinition.zuschlAbs4 && 0.6,
          align: 'right',
          width: '8rem',
          off: allianzModus && !allianzAllowZuschl,
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'zuschlAbs5',
          sortable: false,
          header: kondiDefinition.zuschlAbs5Label,
          headerTip: kondiDefinition.zuschlAbs5Tip,
          opacity: !kondiDefinition.zuschlAbs5 && 0.6,
          align: 'right',
          width: '8rem',
          off: allianzModus && !allianzAllowZuschl,
          body: (row, { column }) => (
            <KondiValueCell
              row={row}
              field={column.field as string}
              setKondiValue={setKondiValue}
              readonly={readonly}
              kondiDefinition={kondiDefinition}
              sonderpreisRabattierbar={sonderpreisRabattierbar}
            />
          )
        },
        {
          field: 'ebeneRabattgruppe',
          sortable: false,
          header: kondiDefinition.rabattgruppeLabel,
          headerTip:
            'Die Rabattgruppe gilt für die ganze Preisebene bzw. des Standortes. Die enthaltenen Rabattwerte werden für alle Staffelmengen herangezogen.',
          width: '14rem',
          visible: (row) => row.standortEk,
          off: !standortMode || ansichtStaffelWert !== 0,
          body: (row) => (
            <RabattgruppeField
              value={row.ebeneRabattgruppeId}
              options={ekRabattgruppen}
              referencePreisEbeneId={row.standortId}
              artikelRabattgruppeId={artikelRabattgruppeId}
              allianzModus={allianzModus}
              error={errors[row.preisEbene?.id + '_' + row.staffelmenge]}
              readonly={
                readonly ||
                row.readonly ||
                !row.standortEk ||
                (!sonderpreisRabattierbar && (row.sonderpreis != null || row.sonderpreisOnly))
              }
              onChange={(value: any) => {
                dispatch({
                  type: EkPreiseUsecase.SETEKRABATTGRUPPE,
                  kondiEbeneId: row.standortId,
                  value
                })
                dispatch({
                  type: EkPreiseUsecase.SETSONDERPREISRABATTIERBAR,
                  value:
                    !sonderpreisRabattierbar && (row.sonderpreis != null || row.sonderpreisOnly)
                })
              }}
            />
          )
        },
        {
          field: 'ebeneAllianzRabattgruppenId',
          sortable: false,
          header: 'Allianz-EK-RG',
          headerTip: 'Allianz EK-Rabattgruppe',
          width: '14rem',
          hideIfEmpty: true,
          body: (row) => (
            <RabattgruppeField
              value={row.ebeneAllianzRabattgruppenId}
              options={ekRabattgruppen}
              allianzModus={allianzModus}
              readonly
            />
          )
        },
        {
          field: nameOf<EkKonditionenWertEdit>('rabattgruppeId'),
          sortable: false,
          header: kondiDefinition.rabattgruppeLabel,
          headerTip: (
            <span>
              {(!standortMode || ansichtStaffelWert === 0) && (
                <span>
                  Rabattgruppe für Staffelmenge 0 ist die der Preisebene bzw. des Standortes.
                  <br />
                  <br />
                </span>
              )}
              Rabattgruppen an Staffelwerten != 0 dienen nur zur Markierung. Es werden keine Werte
              verwendet oder Rabatte berechnet.
            </span>
          ),
          width: '14rem',
          off: standortMode && ansichtStaffelWert === 0,
          body: (row) =>
            row.staffelmenge === 0 ? (
              <RabattgruppeField
                value={row.ebeneRabattgruppeId}
                options={ekRabattgruppen}
                referencePreisEbeneId={row.standortId}
                artikelRabattgruppeId={artikelRabattgruppeId}
                allianzModus={allianzModus}
                readonly
              />
            ) : (
              <RabattgruppeField
                value={row.rabattgruppeId}
                options={ekRabattgruppen}
                referencePreisEbeneId={null}
                artikelRabattgruppeId={null}
                allianzModus={allianzModus}
                error={errors[row.preisEbene?.id + '_' + row.staffelmenge]}
                readonly={readonly || row.readonly}
                onChange={(value: any) =>
                  dispatch({
                    type: EkPreiseUsecase.SETSTAFFELEKRABATTGRUPPE,
                    kondiEbeneId: row.standortId,
                    staffelmenge: row.staffelmenge,
                    value
                  })
                }
              />
            )
        },
        {
          field: 'ebeneRabattVerwendung',
          header: 'EK-RV',
          headerTip: 'EK-Rabattverwendung',
          sortable: false,
          width: '2rem',
          visible: (row) =>
            row.standortEk && row.ebeneRabattgruppeId && row.effektiveRabattgruppeWerte,
          off: (allianzModus && !allianzAllowZuschl) || !standortMode || ansichtStaffelWert !== 0,
          body: (row) => (
            <RabattverwendungField
              value={row.ebeneRabattVerwendung}
              onChange={(value: any) =>
                dispatch({
                  type: EkPreiseUsecase.SETEKRABATTVERWENDUNG,
                  kondiEbeneId: row.standortId,
                  value
                })
              }
              required
              readonly={
                readonly ||
                row.readonly ||
                !row.standortEk ||
                (!sonderpreisRabattierbar && (row.sonderpreis != null || row.sonderpreisOnly)) ||
                row.ebeneRabattgruppeId == null
              }
            />
          )
        },
        {
          field: 'ebeneStaffelgruppeId',
          header: 'Staffelgruppe',
          visible: (row) => row.standortEk,
          off: !standortMode || ansichtStaffelWert !== 0,
          body: (row) => (
            <StaffelgruppenField
              options={staffelgruppen}
              value={row.ebeneStaffelgruppeId}
              noClear
              onChange={(e) =>
                dispatch({
                  type: EkPreiseUsecase.SETSTAFFELGRUPPE,
                  kondiEbeneId: row.standortId,
                  value: e.target.value
                })
              }
              readonly={!!(readonly || row.readonly || !row.standortEk)}
            />
          )
        },
        {
          field: 'ekFreiHaus',
          sortable: false,
          header: kondiDefinition.ekKondiFreiHausLabel,
          headerTip: kondiDefinition.ekKondiFreiHausTip,
          type: 'money2',
          visible: (row) => row.standortEk,
          width: '8rem'
        },
        {
          field: 'ekFreiHausDiff',
          sortable: false,
          header: kondiDefinition.ekKondiFreiDiffLabel,
          headerTip: kondiDefinition.ekKondiFreiDiffTip,
          type: 'money2',
          visible: (row) => row.standortEk,
          width: '8rem'
        }
      ] as Column<EkKonditionenWertEdit>[],
    [
      kondiDefinition,
      standortMode,
      allianzModus,
      allianzAllowZuschl,
      ansichtStaffelWert,
      classes.checkbox,
      readonly,
      dispatch,
      setKondiValue,
      sonderpreisRabattierbar,
      ekRabattgruppen,
      artikelRabattgruppeId,
      errors,
      staffelgruppen
    ]
  )

  const getRowStyle = useCallback(
    (row: EkKonditionenWertEdit) => {
      const arr = getDataColor(row)
      return arr?.length === 2 ? { backgroundColor: arr[0], color: arr[1] } : undefined
    },
    [getDataColor]
  )

  return (
    <DataTable
      name="EkPreisKonditionTable"
      className={classes.table}
      columns={columns}
      value={konditionen}
      rowStyle={getRowStyle}
      dense
      selectMode="none"
      focustype="none"
      rowFiller
    />
  )
}
