import { BuildCircleOutlined } from '@mui/icons-material'
import { Grid2, Tooltip } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import {
  LieferantDisplayJson,
  LieferantDisplayTinyJson,
  SuchLieferantTyp
} from '@one/typings/apiTypings'
import { Link } from '@utils/ui/Navigation/Link'

export interface LieferantCellProps {
  lieferant: LieferantDisplayJson | LieferantDisplayTinyJson
  asLink?: boolean
  emptyText?: string
}

export const LieferantCell = ({ lieferant, asLink, emptyText = null }: LieferantCellProps) => {
  if (lieferant == null) {
    return emptyText && <>{emptyText}</>
  }

  const { id, typ, name1, name2, ort, nummer, eigenlistung } = lieferant
  const name = name1 && `${name1}${name2 ? ' ' + name2 : ''}`
  const label = lieferant?.notanlage ? 'Notlieferant' : `${nummer ?? ''} ${name} ${ort ?? ''}`
  const el = eigenlistung ? (
    <Grid2>
      <Tooltip title="Eigenlistung">
        <BuildCircleOutlined fontSize="small" />
      </Tooltip>
    </Grid2>
  ) : (
    <></>
  )
  if (asLink) {
    const link =
      (typ === SuchLieferantTyp.ABO && AppPaths.LieferantAllianzFn(id)) ||
      (typ === SuchLieferantTyp.ZENTRAL && AppPaths.LieferantListungFn(id)) ||
      AppPaths.LieferantFn(id)
    return (
      <Link to={link}>
        <Grid2 container direction="row" flexWrap="nowrap" gap={1}>
          <Grid2>{label}</Grid2>
          {el}
        </Grid2>
      </Link>
    )
  }

  return (
    <Grid2 container direction="row" flexWrap="nowrap" gap={1}>
      <Grid2>{label}</Grid2>
      {el}
    </Grid2>
  )
}
