import { green, orange, red } from '@mui/material/colors'
import { AppPaths } from '@one/AppPaths'
import { EventNames } from '@one/EventNames'
import { UserRoles } from '@one/UserRoles'
import { api } from '@one/api'
import { LieferantAenderungenPanel } from '@one/components/Lieferant/LieferantAenderungenPanel'
import {
  LieferantAboBearbeitenJson,
  LieferantAboJson,
  LieferantAbonniertStatus
} from '@one/typings/apiTypings'
import { useModelMgr } from '@utils/modelmgr'
import { AppContext } from '@utils/ui/App/AppContext'
import { RouteContext } from '@utils/ui/App/AppRoute'
import { Button } from '@utils/ui/Buttons/Button'
import { ButtonRow } from '@utils/ui/Buttons/ButtonRow'
import { ReloadButton } from '@utils/ui/Buttons/ReloadButton'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { useMessageDialog } from '@utils/ui/MessageDialog'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { dataFromEvent } from '@utils/utils'
import { useCallback, useContext, useMemo } from 'react'
import { LieferantAboForm } from './LieferantAboForm'
import { ThemeContext } from '@utils/ui/Theme'

export interface LieferantAboEdit extends LieferantAboJson {
  dzLieferantConnected?: boolean
}

const validate = (model: LieferantAboEdit) => {
  const errors = {} as any

  if (!model.lieferant) {
    errors.lieferant = 'Lieferant ist ein Pflichtfeld'
  }
  return errors
}

const saveMutator = (model: LieferantAboEdit): LieferantAboJson => {
  const { dzLieferantConnected, ...rest } = model
  return {
    ...rest,
    setzeStatusNeuanlage: model.dzLieferant == null,
    doppelfehler: true // TODO immer true?
  }
}

const editMutator = (model: LieferantAboJson): LieferantAboEdit => {
  return {
    ...model,
    dzLieferantConnected: model.dzLieferant != null
  }
}

export const LieferantAboView = () => {
  const { id, lieferantId } = useContext(RouteContext) as any

  const { checkUserRole } = useContext(AppContext)

  const {
    model,
    envelope,
    isNew,
    isChanged,
    errors,
    uiLock,
    onValueChange,
    save,
    reload,
    updModel
  } = useModelMgr<LieferantAboJson, LieferantAboEdit, LieferantAboBearbeitenJson, LieferantAboJson>(
    {
      id,
      api,
      title: 'Lieferantenabo',
      unwrapField: 'abo',
      rest: 'lieferantabo',
      restps: { zeLieferantId: lieferantId },
      init: { selektionen: [] },
      eventName: EventNames.ARTIKELABO,
      validate,
      saveMutator,
      editMutator
    }
  )

  const { askToConfirm } = useMessageDialog()

  const { darkMode } = useContext(ThemeContext)

  const colorVal = darkMode ? 500 : 100

  const notanlageLieferant = model?.lieferant?.notanlage

  const handleSave = useCallback(() => {
    if (model.dzLieferant == null && (model.setzeStatusAbonniert || isNew)) {
      askToConfirm({
        severity: 'severe',
        title: 'Lieferant neu im ERP anlegen und abonnieren',
        extraConfirm: true,
        message: (
          <span>
            <div style={{ fontWeight: 'bold' }}>
              Es wurde kein Lieferant aus dem ERP-System zugeordnet!
            </div>
            <br />
            <div>
              Bitte bestätigen Sie, dass ein neuer Lieferant im ERP-System angelegt werden soll.
            </div>
            <br />
            <div style={{ fontWeight: 'bold', fontSize: '80%' }}>
              Vergewissern Sie sich, dass der Lieferant nicht bereits im ERP-System existiert!
            </div>
          </span>
        ),
        confirmLabel: 'Anlegen und abonnieren',
        onConfirm: () => save()
      })
    } else if (model.verbindungLoesen) {
      askToConfirm({
        severity: 'severe',
        title: 'Verbindung Lieferant zur Listung lösen',
        extraConfirm: true,
        message: (
          <span>
            <div style={{ fontWeight: 'bold' }}>
              Eine Lieferantenbeziehung sollte nur gelöst werden, wenn sicher ist, dass eine falsche
              Zuordnung getroffen wurde!
            </div>
            <br />
            <div>
              Der zentrale Lieferant kann nach dem Lösen der Verbindung einem anderen Lieferanten im
              ERP-System zugeordnet werden.
            </div>
            <br />
            <div>
              Bitte bestätigen Sie, dass die Verbindung zum zentralen Lieferanten göst werden soll.
            </div>
            <br />
            <div style={{ fontWeight: 'bold', fontSize: '80%' }}>
              Das Vorhandensein von bereits abonnierten Artikeln kann zu Fehlern führen!
            </div>
          </span>
        ),
        confirmLabel: 'Verbindung lösen',
        onConfirm: () => save()
      })
    } else {
      const customMessage = model.setzeStatusUnerwuenscht ? 'Lieferant wird nicht verwendet' : null
      save(null, customMessage)
    }
  }, [
    model.dzLieferant,
    model.setzeStatusAbonniert,
    model.verbindungLoesen,
    model.setzeStatusUnerwuenscht,
    isNew,
    askToConfirm,
    save
  ])

  const onChange = (e) => {
    const { name, value } = dataFromEvent(e)
    if (name === 'dzLieferant' && value == null) {
      updModel({ dzLieferant: null, setzeStatusAbonniert: false })
    } else {
      onValueChange(e)
    }
  }

  const buttons = useMemo(() => {
    const canKonfliktUeberschreiben =
      model.konflikt && !(model.setzeStatusUnerwuenscht || model.verbindungLoesen)

    const canAbonnieren =
      !isNew &&
      (model.status === LieferantAbonniertStatus.UNERWUENSCHT ||
        model.status === LieferantAbonniertStatus.UNBESTIMMT ||
        !model.status) &&
      !(model.setzeStatusUnerwuenscht || model.verbindungLoesen)

    const canVerbindungLoesen =
      envelope.abo?.dzLieferant &&
      (model.status === LieferantAbonniertStatus.UNERWUENSCHT ||
        model.status === LieferantAbonniertStatus.UNBESTIMMT) &&
      !(model.setzeStatusUnerwuenscht || model.setzeStatusAbonniert) &&
      checkUserRole(UserRoles.ADMIN)

    const canNichtMehrAbonnieren =
      model.status === LieferantAbonniertStatus.ABONNIERT &&
      !(model.aenderungenUebernehmen || model.setzeStatusAbonniert || model.verbindungLoesen)

    const canLieferantNichtVerwenden =
      ((model.status === LieferantAbonniertStatus.UNBESTIMMT &&
        !model.dzLieferant &&
        !lieferantId) ||
        (isNew && lieferantId)) &&
      !(model.aenderungenUebernehmen || model.setzeStatusAbonniert || model.verbindungLoesen)

    const canAenderungenUebernehmen = model.aenderungen && !model.setzeStatusUnerwuenscht

    return (
      <ButtonRow>
        <Checkbox
          label="Konflikt überschreiben"
          backgroundColor={orange[colorVal]}
          size="small"
          name="konfliktUeberschreiben"
          checked={model.konfliktUeberschreiben}
          onChange={onValueChange}
          color="default"
          paddingTop={0}
          visible={canKonfliktUeberschreiben && !notanlageLieferant}
        />
        <Checkbox
          label="Verbindung zum ERP-Lieferant lösen"
          backgroundColor={red[colorVal]}
          size="small"
          name="verbindungLoesen"
          checked={model.verbindungLoesen}
          onChange={onValueChange}
          color="error"
          visible={canVerbindungLoesen && !notanlageLieferant}
        />
        <Checkbox
          backgroundColor={red[colorVal]}
          size="small"
          label="Abo beenden"
          name="setzeStatusUnerwuenscht"
          checked={model.setzeStatusUnerwuenscht}
          onChange={onValueChange}
          color="error"
          visible={canNichtMehrAbonnieren && !notanlageLieferant}
        />
        <Checkbox
          label="Lieferant nicht verwenden"
          backgroundColor={red[colorVal]}
          size="small"
          name="setzeStatusUnerwuenscht"
          checked={model.setzeStatusUnerwuenscht}
          onChange={onValueChange}
          color="default"
          paddingTop={0}
          visible={canLieferantNichtVerwenden}
        />
        <Checkbox
          label="Änderungen übernehmen"
          backgroundColor={orange[colorVal]}
          size="small"
          name="aenderungenUebernehmen"
          checked={model.aenderungenUebernehmen}
          onChange={onValueChange}
          color="default"
          paddingTop={0}
          visible={canAenderungenUebernehmen && !notanlageLieferant}
        />
        <Checkbox
          label="Abonnieren"
          backgroundColor={green[colorVal]}
          size="small"
          name="setzeStatusAbonniert"
          checked={model.setzeStatusAbonniert}
          onChange={onValueChange}
          color="default"
          paddingTop={0}
          visible={canAbonnieren && !notanlageLieferant}
        />
        <Button
          onClick={handleSave}
          disabled={!isChanged || (notanlageLieferant && !model.setzeStatusUnerwuenscht)}
          label={isNew ? 'Abonnieren' : 'Speichern'}
          variant="contained"
        />
        <ReloadButton onClick={reload} isNew={isNew} isChanged={isChanged} />
      </ButtonRow>
    )
  }, [
    checkUserRole,
    colorVal,
    envelope.abo?.dzLieferant,
    handleSave,
    isChanged,
    isNew,
    lieferantId,
    model.aenderungen,
    model.aenderungenUebernehmen,
    model.dzLieferant,
    model.konflikt,
    model.konfliktUeberschreiben,
    model.setzeStatusAbonniert,
    model.setzeStatusUnerwuenscht,
    model.status,
    model.verbindungLoesen,
    notanlageLieferant,
    onValueChange,
    reload
  ])

  return (
    <StatePlane uiLock={uiLock} altLink={AppPaths.LieferantAboUebersicht}>
      <Frame space>
        <FrameRow>
          <LieferantAboForm
            model={model}
            errors={errors}
            isNew={isNew}
            onChange={onChange}
            updModel={updModel}
            isNewLieferant={Boolean(lieferantId)}
            readonly={notanlageLieferant}
          />
        </FrameRow>
        <FrameBody>
          <LieferantAenderungenPanel
            lieferant={model.lieferant}
            dzLieferant={model.dzLieferant}
            lieferantSyncEinstId={model.lieferantSyncEinstId}
            aenderungen={model.aenderungen}
            konflikt={model.konflikt}
            changedAttributes={model.changedAttributes}
            changedRabattgruppen={model.changedRabattgruppen}
            konditionDefinition={envelope.kondiDefinition}
            quellenWechsel={model.quellenwechsel}
          />
        </FrameBody>
        <FrameRow>{buttons}</FrameRow>
      </Frame>
    </StatePlane>
  )
}
