import { ExportToErpSearcherCriteriaJson } from '@one/typings/apiTypings'
import { ChangeFunction } from '@utils/formstate'
import { Action } from '@utils/ui/Action'
import { CardEx } from '@utils/ui/CardEx'
import { Grid2 as Grid } from '@mui/material'
import { ShortCutHandler } from '@utils/ui/ShortCutHandler'
import { DateField } from '@utils/ui/fields/DateField'
import { useMemo } from 'react'

export interface ExportToErpUebersichtParamsProps {
  value: ExportToErpSearcherCriteriaJson
  onChange: ChangeFunction<ExportToErpSearcherCriteriaJson>
  onSearch: () => void
}

export const ExportToErpUebersichtParams = ({
  value,
  onChange,
  onSearch
}: ExportToErpUebersichtParamsProps) => {
  const actions = useMemo(() => {
    return [
      {
        text: 'Suchen',
        onClick: onSearch
      }
    ] as Action[]
  }, [onSearch])

  return (
    <ShortCutHandler shortcuts={{ Enter: onSearch }}>
      <CardEx backLink title="ERP Exporte" collapsiable bottomActions={actions}>
        <Grid container spacing={3}>
          <Grid size={{xs:6, sm:4, md:3, lg:2}}>
            <DateField
              // autoOk
              label="Gestartet von"
              name="vonDatum"
              disableToolbar
              value={value.vonDatum}
              onChange={onChange}
            />
          </Grid>
          <Grid size={{xs:6, sm:4, md:3, lg:2}}>
            <DateField
              // autoOk
              label="Gestartet bis"
              name="bisDatum"
              disableToolbar
              value={value.bisDatum}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </CardEx>
    </ShortCutHandler>
  )
}
