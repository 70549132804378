import { api } from '@one/api'
import { LieferantDisplayJson, LieferantSearcherCriteriaJson } from '@one/typings/apiTypings'
import { useSearcherState } from '@utils/searcher'
import { AppContext } from '@utils/ui/App/AppContext'
import { CancelButton } from '@utils/ui/Buttons/CancelButton'
import { SelectButton } from '@utils/ui/Buttons/SelectButton'
import { DialogEx } from '@utils/ui/DialogEx'
import { Frame, FrameBody, FrameRow } from '@utils/ui/Frame'
import { stopPropagation } from '@utils/ui/uiutils'
import { arrayFromSet, singleFromSet } from '@utils/utils'
import { useContext, useState } from 'react'
import { LieferantenUebersichtParams } from './LieferantenUebersicht/LieferantenUebersichtParams'
import { LieferantenUebersichtTable } from './LieferantenUebersicht/LieferantenUebersichtTable'

export interface LieferantSearchDialogProps {
  onClose: (select?: any | any[]) => void
  open?: boolean
  defaultZentral?: boolean
  disableZentral?: boolean
  defaultDezentral?: boolean
  disableDezentral?: boolean
  auchGeloeschte?: boolean
  multiselect?: boolean
  notlieferant?: boolean
}

export const LieferantSearchDialog = ({
  onClose,
  open,
  defaultZentral,
  disableZentral,
  defaultDezentral = !disableZentral,
  disableDezentral,
  auchGeloeschte,
  multiselect,
  notlieferant
}: LieferantSearchDialogProps) => {
  const { isAllianzMitglied } = useContext(AppContext)

  const zentralName = isAllianzMitglied ? 'forAbo' : 'zentral'

  const { criteria, onCriteriaChange, result, search } = useSearcherState<
    LieferantSearcherCriteriaJson,
    LieferantDisplayJson
  >({
    api,
    url: '/lieferant',
    doOpen: false,
    initialParams: {
      mitStatistik: false,
      [zentralName]: defaultZentral,
      dezentral: defaultDezentral,
      auchGeloeschte,
      notlieferant
    }
  })

  const [selected, setSelected] = useState(new Set())

  const handleAuswahl = () => {
    onClose(multiselect ? arrayFromSet(selected) : singleFromSet(selected))
  }

  const handleCancelFilter = () => {
    onClose()
  }

  const onRowDoubleClick = (row, e) => {
    stopPropagation(e)
    onClose(multiselect ? [row] : row)
  }

  const actions = (
    <>
      <SelectButton onClick={handleAuswahl} disabled={selected.size === 0} />
      <CancelButton onClick={handleCancelFilter} />
    </>
  )

  return (
    <DialogEx
      open={open}
      onClose={handleCancelFilter}
      maxWidth="xl"
      title="Lieferant suchen"
      helpKey="lieferant_suchen_dialog"
      actions={actions}
      fullWidth
      height="680px"
    >
      <Frame space>
        <FrameRow>
          <LieferantenUebersichtParams
            value={criteria}
            onChange={onCriteriaChange}
            onSearch={search}
            disableZentral={disableZentral}
            disableDezentral={disableDezentral}
            borderless
            zentralName={zentralName}
          />
        </FrameRow>
        <FrameBody>
          <LieferantenUebersichtTable
            result={result}
            selectMode={multiselect ? 'multi' : 'single'}
            onSelect={setSelected}
            onRowDoubleClick={onRowDoubleClick}
            borderless
          />
        </FrameBody>
      </Frame>
    </DialogEx>
  )
}
