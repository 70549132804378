import {
  AboArtikelEanGruppeStatus,
  ArtikelAbonniertStatus,
  ArtikelAenderungTyp,
  ArtikelSelektionDisplayJson,
  ArtikelSelektionEintragChangeJson,
  ArtikelSelektionEintragJson,
  ArtikelSelektionJson
} from '@one/typings/apiTypings'

export enum ArtikelAbonniertStatusEx {
  NEU = 'NEU',
  GEAENDERT = 'GEAENDERT',
  KONFLIKT_DZ = 'KONFLIKT_DZ',
  AUSLISTUNG = 'AUSLISTUNG',
  AUSGELISTET = 'AUSGELISTET',
  UNTERBROCHEN = 'UNTERBROCHEN',
  UNBESTIMMT = 'UNBESTIMMT',
  EINGESCHL = 'EINGESCHL',
  AUSGESCHL = 'AUSGESCHL',
  BESTAETIGT = 'BESTAETIGT',
  EIGENLISTUNG = 'EIGENLISTUNG'
}

export const ArtikelAbonniertStatusExList = [
  ArtikelAbonniertStatusEx.NEU,
  ArtikelAbonniertStatusEx.GEAENDERT,
  ArtikelAbonniertStatusEx.KONFLIKT_DZ,
  ArtikelAbonniertStatusEx.AUSLISTUNG,
  ArtikelAbonniertStatusEx.AUSGELISTET,
  ArtikelAbonniertStatusEx.UNTERBROCHEN,
  ArtikelAbonniertStatusEx.UNBESTIMMT,
  ArtikelAbonniertStatusEx.EINGESCHL,
  ArtikelAbonniertStatusEx.AUSGESCHL,
  ArtikelAbonniertStatusEx.BESTAETIGT,
  ArtikelAbonniertStatusEx.EIGENLISTUNG
]

export const buildArtikelAbonniertStatusEx = (
  aboStatus: ArtikelAbonniertStatus,
  aenderungsTyp: ArtikelAenderungTyp | null,
  eigenlistung: boolean | null
): ArtikelAbonniertStatusEx => {
  if( eigenlistung ) {
    // Workaround, solange abonnieren
    return ArtikelAbonniertStatusEx.EIGENLISTUNG
  }
  if (aenderungsTyp) {
    switch (aenderungsTyp) {
      case ArtikelAenderungTyp.NEU:
        return ArtikelAbonniertStatusEx.NEU
      case ArtikelAenderungTyp.GEAENDERT:
        return ArtikelAbonniertStatusEx.GEAENDERT
      case ArtikelAenderungTyp.KONFLIKT_DZ:
        return ArtikelAbonniertStatusEx.KONFLIKT_DZ
      case ArtikelAenderungTyp.AUSGELISTET:
        return ArtikelAbonniertStatusEx.AUSLISTUNG
    }
  }
  if (aboStatus) {
    switch (aboStatus) {
      case ArtikelAbonniertStatus.UNTERBROCHEN:
        return ArtikelAbonniertStatusEx.UNTERBROCHEN
      case ArtikelAbonniertStatus.UNBESTIMMT:
        return ArtikelAbonniertStatusEx.UNBESTIMMT
      case ArtikelAbonniertStatus.EINGESCHL:
        return ArtikelAbonniertStatusEx.EINGESCHL
      case ArtikelAbonniertStatus.AUSGESCHL:
        return ArtikelAbonniertStatusEx.AUSGESCHL
    }
  }

  return null
}

export const buildArtikelAbonniertStatusChange = (
  statusEx: ArtikelAbonniertStatusEx | null
): ArtikelAbonniertStatus | null => {
  if (statusEx == null) {
    return null
  }
  switch (statusEx) {
    case ArtikelAbonniertStatusEx.EINGESCHL:
      return ArtikelAbonniertStatus.EINGESCHL
    case ArtikelAbonniertStatusEx.AUSGESCHL:
      return ArtikelAbonniertStatus.AUSGESCHL
    case ArtikelAbonniertStatusEx.UNBESTIMMT:
      return ArtikelAbonniertStatus.UNBESTIMMT
    case ArtikelAbonniertStatusEx.BESTAETIGT:
      return ArtikelAbonniertStatus.EINGESCHL
  }
  console.error('statusEx ignored', statusEx)
  return null
}

export interface ArtikelSelektionEintragEdit extends Omit<ArtikelSelektionEintragJson, 'status'> {
  statusEx: ArtikelAbonniertStatusEx
  attributWarnungen: boolean
}

export interface ArtikelSelektionEintragChangeEdit extends ArtikelSelektionEintragChangeJson {
  statusExOld?: ArtikelAbonniertStatusEx
  statusEx?: ArtikelAbonniertStatusEx
  arsstIdOld?: number
  hauptSelektionIdOld?: number
  uebernehmeBetriebsTypSwitch?: boolean
  uebernehmeEanSwitch?: boolean
  eanGruppeStatusOld?: AboArtikelEanGruppeStatus
}

export interface ArtikelSelektionEdit extends ArtikelSelektionJson {
  ergebnisse: ArtikelSelektionEintragEdit[]
  artikelSelektionenMap: Map<number, ArtikelSelektionDisplayJson>
  changesMap: Map<number, ArtikelSelektionEintragChangeEdit>
  aenderungenIdx: Map<number, number>
  zubestaetigenHints: Map<number, string>
}
