import {
  EigenlistungsArtikelBearbeitenJson,
  LieferantDisplayJson,
  PimArtikelVersionComplianceLandJson,
  PimArtikelVersionDisplayJson,
  PimArtikelVersionLieferantJson,
  PimArtikelVersionLieferantLandJson,
  PimArtikelVersionMengeneinheitJson,
  PimArtikelVersionPackstueckJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ModelAction } from '@utils/modelmgr'
import { isValidGTIN, safeArray, updateObjectField } from '@utils/utils'
import { isDateLower } from '@utils/dateutils'

const addMengeneinheit = (state: SeArtikelJson): SeArtikelJson => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        mengeneinheiten: [...state.pimArtikel.version.mengeneinheiten, {}]
      }
    }
  }
}
const removeMengeneinheit = (state: SeArtikelJson, me: PimArtikelVersionMengeneinheitJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        mengeneinheiten: state.pimArtikel.version.mengeneinheiten.filter((it) => it != me)
      }
    }
  }
}

const addArtikelLieferant = (
  state: SeArtikelJson,
  lieferanten: LieferantDisplayJson[]
): SeArtikelJson => {
  const artikelLieferanten: PimArtikelVersionLieferantJson[] = safeArray(
    state.pimArtikel.version.lieferanten
  )
    .concat(
      lieferanten.map(
        (lieferant) =>
          ({
            lieferant,
            seErgaenzung: !state.eigenlistung,
            kontext: { kontext: state.pimArtikel.versionKontext.kontext }
          }) as PimArtikelVersionLieferantJson
      )
    )
    .filter(Boolean)
    .filter((li, idx, arr) => arr.findIndex((it) => it.lieferant.id === li.lieferant.id) === idx)

  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        lieferanten: artikelLieferanten
      }
    }
  }
}

const removeLieferant = (
  state: SeArtikelJson,
  pimArtikelVersionLieferant: PimArtikelVersionLieferantJson
) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        lieferanten: state.pimArtikel.version.lieferanten.filter(
          (it) => it !== pimArtikelVersionLieferant
        )
      }
    }
  }
}

const addComplianceLand = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        compliance: {
          ...state.pimArtikel.version.compliance,
          laender: [...state.pimArtikel.version.compliance.laender, {}]
        }
      }
    }
  }
}

const removeComplianceLand = (state: SeArtikelJson, land: PimArtikelVersionComplianceLandJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        compliance: {
          ...state.pimArtikel.version.compliance,
          laender: state.pimArtikel.version.compliance.laender.filter((it) => it !== land)
        }
      }
    }
  }
}

const addLieferantLand = (state: SeArtikelJson, lieferant: PimArtikelVersionLieferantJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        lieferanten: state.pimArtikel.version.lieferanten.map((it) =>
          it === lieferant
            ? {
                ...it,
                laender: [...safeArray(it.laender), {}]
              }
            : it
        )
      }
    }
  }
}

const removeLieferantLand = (
  state: SeArtikelJson,
  lieferant: PimArtikelVersionLieferantJson,
  land: PimArtikelVersionLieferantLandJson
) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        lieferanten: state.pimArtikel.version.lieferanten.map((it) =>
          it === lieferant
            ? {
                ...it,
                laender: it.laender.filter((it) => it !== land)
              }
            : it
        )
      }
    }
  }
}

const addDisplayArtikel = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        displayArtikel: [...safeArray(state.pimArtikel.version.displayArtikel), {}]
      }
    }
  }
}

const removeDisplayArtikel = (state: SeArtikelJson, display: PimArtikelVersionDisplayJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        displayArtikel: state.pimArtikel.version.displayArtikel.filter((it) => it !== display)
      }
    }
  }
}

const addFliese = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        fliese: state.pimArtikel.version.fliese || {}
      }
    }
  }
}

const removeFliese = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        fliese: null
      }
    }
  }
}

const addSteuerLand = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        laender: [...safeArray(state.pimArtikel.version.laender), {}]
      }
    }
  }
}

const removeSteuerLand = (state: SeArtikelJson, land: PimArtikelVersionComplianceLandJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        laender: state.pimArtikel.version.laender.filter((it) => it !== land)
      }
    }
  }
}

const addPflanze = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        pflanze: state.pimArtikel.version.pflanze || {}
      }
    }
  }
}

const removePflanze = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        pflanze: null
      }
    }
  }
}

const addPackstueck = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        packstuecke: [...safeArray(state.pimArtikel.version.packstuecke), {}]
      }
    }
  }
}

const removePackstueck = (state: SeArtikelJson, packstueck: PimArtikelVersionPackstueckJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        packstuecke: state.pimArtikel.version.packstuecke.filter((it) => it !== packstueck)
      }
    }
  }
}

// export interface EigenlistungsArtikelError extends ErrorsType {
//   kopf: any
// }

const validateEigenlistungsArtikel = (
  model: SeArtikelJson,
  lastModel: SeArtikelJson,
  envelope: EigenlistungsArtikelBearbeitenJson
) => {
  // let errors: EigenlistungsArtikelError = {
  //    kopf: {}
  // }

  let errors = {}

  if (!model.pimArtikel?.version?.artikelArt) {
    // errors.kopf['pimArtikel.version.artikelArt'] = 'Artikelart muss angegeben werden'
    errors = updateObjectField(
      errors,
      'pimArtikel.version.artikelArt',
      'Artikelart muss angegeben werden'
    )
  }
  for (let i = 0; i < model.pimArtikel?.version?.displayArtikel?.length; i++) {
    if (!model.pimArtikel?.version.displayArtikel[i].hageNummer) {
      errors = updateObjectField(
        errors,
        'model.pimArtikel?.version.displayArtikel.[' + i + '].hageNummer',
        'Hagenummer muss in Displayartikel ' + i + ' gesetzt sein'
      )
    } else if (
      model.pimArtikel?.version.displayArtikel[i].hageNummer ===
      model.pimArtikel.version?.hageNummer
    ) {
      errors = updateObjectField(
        errors,
        'model.pimArtikel?.version.displayArtikel.[' + i + '].hageNummer',
        'Displayartikel darf nicht auf sich selbst verweisen'
      )
    }
  }
  let lieferanten = model.pimArtikel?.version?.lieferanten
  for (let l = 0; l < lieferanten?.length; l++) {
    let kontext = lieferanten[l].kontext
    if (isDateLower(kontext?.ekSperreBis, kontext?.ekSperreAb, false)) {
      errors = updateObjectField(
        errors,
        `pimArtikel.version.lieferanten.[${l}].kontext.ekSperreAb`,
        `Invalider Zeitraum für EK-Sperre für Lieferant ${lieferanten[l].lieferant?.name1}`
      )
    }
    if (isDateLower(kontext?.mamGueltigBis, kontext?.mamGueltigAb, false)) {
      errors = updateObjectField(
        errors,
        `pimArtikel.version.lieferanten.[${l}].kontext.mamGueltigAb`,
        `Invalider Zeitraum für MAM Gültigkeit für Lieferant ${lieferanten[l].lieferant?.name1}`
      )
    }
    if (kontext?.bme == null) {
      errors = updateObjectField(
        errors,
        `pimArtikel.version.lieferanten.[${l}].kontext.bme`,
        `Bitte geben Sie die BME für Lieferant ${lieferanten[l].lieferant?.name1} an`
      )
    }
  }
  let versionKontext = model.pimArtikel?.versionKontext
  if (isDateLower(versionKontext?.vkSperreBis, versionKontext?.vkSperreAb, false)) {
    errors = updateObjectField(
      errors,
      `pimArtikel.versionKontext.vkSperreAb`,
      `Invalider Zeitraum für VK-Sperre`
    )
  }

  const eans = new Set<string>()
  for (let i = 0; i < model.pimArtikel?.version?.eans?.length; i++) {
    if (!isValidGTIN(model.pimArtikel?.version.eans[i].gtin)) {
      errors = updateObjectField(
        errors,
        'pimArtikel.version.eans.[' + i + '].gtin',
        'Die EAN ist ungültig'
      )
    }
    if (eans.has(model.pimArtikel?.version.eans[i].gtin)) {
      errors = updateObjectField(
        errors,
        'pimArtikel.version.eans.[' + i + '].gtin',
        'Die EAN ist bereits vorhanden'
      )
    } else {
      eans.add(model.pimArtikel?.version.eans[i].gtin)
    }
  }

  return errors
}

const addEAN = (state: SeArtikelJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        eans: [...safeArray(state.pimArtikel.version.eans), { geloescht: false }]
      }
    }
  }
}

const removeEAN = (state: SeArtikelJson, ean: PimArtikelVersionMengeneinheitJson) => {
  return {
    ...state,
    pimArtikel: {
      ...state.pimArtikel,
      version: {
        ...state.pimArtikel.version,
        eans: state.pimArtikel.version.eans.filter((it) => it !== ean)
      }
    }
  }
}

export const EigenlistungsArtikelUseCase = {
  ADDMENGENEINHEIT: 'ADDMENGENEINHEIT',
  REMOVEMENGENEINHEIT: 'REMOVEMENGENEINHEIT',

  ADDLIEFERANT: 'ADDLIEFERANT',
  REMOVELIEFERANT: 'REMOVELIEFERANT',

  ADDCOMPLIANCELAND: 'ADDCOMPLIANCELAND',
  REMOVECOMPLIANCELAND: 'REMOVECOMPLIANCELAND',

  ADDLIEFERANTLAND: 'ADDLIEFERANTLAND',
  REMOVELIEFERANTLAND: 'REMOVELIEFERANTLAND',

  ADDDISPLAYARTIKEL: 'ADDDISPLAYARTIKEL',
  REMOVEDISPLAYARTIKEL: 'REMOVEDISPLAYARTIKEL',

  ADDFLIESE: 'ADDFLIESE',
  REMOVEFLIESE: 'REMOVEFLIESE',

  ADDSTEUERLAND: 'ADDSTEUERLAND',
  REMOVESTEUERLAND: 'REMOVESTEUERLAND',

  ADDPFLANZE: 'ADDPFLANZE',
  REMOVEPFLANZE: 'REMOVEPFLANZE',

  ADDPACKSTUECK: 'ADDPACKSTUECK',
  REMOVEPACKSTUECK: 'REMOVEPACKSTUECK',

  ADDEAN: 'ADDEAN',
  REMOVEEAN: 'REMOVEEAN',

  reducer: (state: SeArtikelJson, action: ModelAction) => {
    switch (action.type) {
      case EigenlistungsArtikelUseCase.ADDMENGENEINHEIT:
        return addMengeneinheit(state)
      case EigenlistungsArtikelUseCase.REMOVEMENGENEINHEIT:
        return removeMengeneinheit(state, action.me)
      case EigenlistungsArtikelUseCase.ADDLIEFERANT:
        return addArtikelLieferant(state, action.lieferanten)
      case EigenlistungsArtikelUseCase.REMOVELIEFERANT:
        return removeLieferant(state, action.pimArtikelVersionLieferant)
      case EigenlistungsArtikelUseCase.ADDCOMPLIANCELAND:
        return addComplianceLand(state)
      case EigenlistungsArtikelUseCase.REMOVECOMPLIANCELAND:
        return removeComplianceLand(state, action.land)
      case EigenlistungsArtikelUseCase.ADDLIEFERANTLAND:
        return addLieferantLand(state, action.lieferant)
      case EigenlistungsArtikelUseCase.REMOVELIEFERANTLAND:
        return removeLieferantLand(state, action.lieferant, action.land)
      case EigenlistungsArtikelUseCase.ADDDISPLAYARTIKEL:
        return addDisplayArtikel(state)
      case EigenlistungsArtikelUseCase.REMOVEDISPLAYARTIKEL:
        return removeDisplayArtikel(state, action.display)
      case EigenlistungsArtikelUseCase.ADDFLIESE:
        return addFliese(state)
      case EigenlistungsArtikelUseCase.REMOVEFLIESE:
        return removeFliese(state)
      case EigenlistungsArtikelUseCase.ADDSTEUERLAND:
        return addSteuerLand(state)
      case EigenlistungsArtikelUseCase.REMOVESTEUERLAND:
        return removeSteuerLand(state, action.land)
      case EigenlistungsArtikelUseCase.ADDPFLANZE:
        return addPflanze(state)
      case EigenlistungsArtikelUseCase.REMOVEPFLANZE:
        return removePflanze(state)
      case EigenlistungsArtikelUseCase.ADDPACKSTUECK:
        return addPackstueck(state)
      case EigenlistungsArtikelUseCase.REMOVEPACKSTUECK:
        return removePackstueck(state, action.packstueck)
      case EigenlistungsArtikelUseCase.ADDEAN:
        return addEAN(state)
      case EigenlistungsArtikelUseCase.REMOVEEAN:
        return removeEAN(state, action.ean)
      default:
        console.error('Unexpected action', action)
        return state
    }
  },

  validate: validateEigenlistungsArtikel
}
