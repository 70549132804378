import { Visibility } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { AppPaths } from '@one/AppPaths'
import { api } from '@one/api'
import {
  AsyncTaskResponseJson,
  ExportDataToErpDetailStatus,
  ProtokollEintragJson
} from '@one/typings/apiTypings'
import { useApiCaller } from '@utils/apicaller'
import { Button } from '@utils/ui/Buttons/Button'
import { Column } from '@utils/ui/DataTable/DataTable'
import { DataTableAction } from '@utils/ui/DataTable/DataTableBody'
import { DataTableCard } from '@utils/ui/DataTable/DataTableCard'
import { Frame, FrameBody } from '@utils/ui/Frame'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { Medal } from '@utils/ui/fields/Medal'
import { StatePlane } from '@utils/ui/planes/StatePlane'
import { arrayFromSet, pathOf } from '@utils/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAsyncTaskPoller } from '@utils/asynctask'
import { noLock, UILockType } from '@utils/uilock'

interface ExportErrorJson {
  /** @format int64 */
  id?: number
  needsExportErp?: boolean
  protokollEintrag?: ProtokollEintragJson
  status?: ExportDataToErpDetailStatus
  /** @format date-time */
  confirmTime?: string
}

const saveMutator = (model: any, payload: any) => {
  return model.exportErrors.filter((row) => row.needsExportErpEdt).map((row) => row.id)
}

export interface ExportFehlerViewProps {
  columns: Column[]
  actions?: DataTableAction[]
  title: string
  name: string
  rest: string
}

export const ExportFehlerView = ({
  columns,
  name,
  title,
  rest,
  actions
}: ExportFehlerViewProps) => {
  const [apiCall, apiBusy] = useApiCaller(api)

  const [uiLock, setUiLock] = useState<UILockType>(noLock)
  const [exportErrors, setExportErrors] = useState<ExportErrorJson[]>([])
  const [selected, setSelected] = useState(new Set<ExportErrorJson>())
  const [isFiltered, setIsFiltered] = useState(false)

  const [asyncPoller] = useAsyncTaskPoller()

  const refresh = () => {
    setExportErrors([])
    apiCall({
      method: 'GET',
      rest: rest + '/errors/load/async-start',
      onSuccess: (data: AsyncTaskResponseJson) => {
        asyncPoller({
          apiCall,
          asyncTask: data.state.asyncTask,
          title: 'Exportfehler laden',
          setUiLock,
          onReady: (asyncTask) => {
            if (asyncTask.status === 'FINISHED') {
              apiCall({
                method: 'GET',
                rest: rest + '/errors/load/async-get',
                params: { key: data.key },
                onSuccess: (x: any) => {
                  setExportErrors(x.exportErrors)
                }
              })
            }
          }
        })
      }
    })
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const perform = (restname: string) => {
    apiCall({
      method: 'POST',
      rest: rest + '/' + restname,
      data: arrayFromSet(selected).map((s) => s.id),
      onSuccess: (data: any) => {
        refresh()
      }
    })
  }

  const tableItems = useMemo(() => {
    if (!exportErrors) {
      return []
    }
    if (isFiltered) {
      return exportErrors
    }
    return exportErrors.filter((item) => !item.needsExportErp)
  }, [isFiltered, exportErrors])

  const performabled = useMemo(
    () =>
      selected.size > 0 && arrayFromSet(selected).find((r) => r.needsExportErp !== false) == null,
    [selected]
  )

  const exportErrorColumns = useMemo(
    () =>
      [
        {
          field: 'protokollEintrag.timestamp',
          header: 'Zeitstempel',
          type: 'datetimesecond'
        },
        {
          field: 'needsExportErp',
          header: 'Status',
          align: 'center',
          valueGetter: (row) => (row.needsExportErp ? 'Im Export' : null),
          body: (row) => <Medal text={row.needsExportErp ? 'Im Export' : 'Offen'} />
        },
        {
          field: 'protokollEintrag.confirmTime',
          header: 'ERP-Bestätigung',
          type: 'datetimesecond'
        },
        {
          field: 'protokollEintrag.message',
          header: 'Fehlermeldung',
          wrapMode: 'normal'
        },
        ...columns
      ] as Column<ExportErrorJson>[],
    [columns]
  )

  const onFilterChange = useCallback(() => {
    setIsFiltered(!isFiltered)
  }, [setIsFiltered, isFiltered])

  // const onSave = useCallback(() => {
  //   save({ then: () => reload() })
  // }, [save, reload])

  const checkbox = (
    <Grid container justifyContent="center">
      <Grid>
        <Checkbox
          label="Zur Wiederholung freigegebene Einträge anzeigen"
          size="small"
          name="filtered"
          checked={isFiltered}
          onChange={onFilterChange}
          color="default"
          paddingTop={0}
        />
      </Grid>
    </Grid>
  )
  const topActions = [
    {
      tooltip: 'Aktualisieren',
      icon: 'refresh',
      onClick: () => refresh()
    }
  ]

  const bottomActions = [
    <Button
      key="rep"
      size="small"
      label="Export wiederholen"
      variant="contained"
      disabled={!performabled}
      onClick={() => perform('retry')}
    />
  ]

  const rowActions = [
    {
      icon: <Visibility />,
      title: 'Job-Protokoll zeigen',
      getLink: (data) =>
        data.protokollEintrag && AppPaths.ProtokollDetailsViewFn(data.protokollEintrag.protokollId)
    },
    ...(actions || [])
  ] as DataTableAction[]

  return (
    <StatePlane wait={apiBusy} uiLock={uiLock}>
      <Frame space>
        <FrameBody>
          <DataTableCard
            title={title}
            name={name}
            backLink
            selectMode="multi"
            selectCol
            selected={selected}
            onSelect={setSelected}
            columns={exportErrorColumns}
            value={tableItems}
            paging
            filterMode="both"
            dense
            localStateName={name}
            header={checkbox}
            bottomActions={bottomActions}
            topActions={topActions}
            actions={rowActions}
            emptyMessage="Keine Meldungen vorhanden"
            wrapMode="nowrap"
            initialOrderBy={[
              {
                key: pathOf<ExportErrorJson, ProtokollEintragJson>('protokollEintrag', 'timestamp'),
                dir: 'desc'
              }
            ]}
          />
        </FrameBody>
      </Frame>
    </StatePlane>
  )
}
