import { ArrowForward, Delete } from '@mui/icons-material'
import { Grid2 as Grid } from '@mui/material'
import { PimMengeneinheitField } from '@one/components/common/PimMengeneinheitField'
import {
  NeonKontext,
  PimArtikelVersionMengeneinheitJson,
  PimMengeneinheitDisplayJson,
  SeArtikelJson
} from '@one/typings/apiTypings'
import { ErrorsType, ModelAction, ValueChangeFn } from '@utils/modelmgr'
import { Button } from '@utils/ui/Buttons/Button'
import { CardEx } from '@utils/ui/CardEx'
import { Checkbox } from '@utils/ui/fields/Checkbox'
import { NumberField } from '@utils/ui/fields/NumberField'
import { StaticField } from '@utils/ui/fields/StaticField'
import { TextField } from '@utils/ui/fields/TextField'
import { aidOf, resolveObjectField } from '@utils/utils'
import React, { useMemo } from 'react'
import { EigenlistungsArtikelUseCase } from './model/EigenlistungsArtikelUseCase'

export interface PimArtikelMengeneinheitProps {
  pimArtikelVersionMengeneinheit: PimArtikelVersionMengeneinheitJson
  mengeneinheiten: PimMengeneinheitDisplayJson[]
  onChange: ValueChangeFn<SeArtikelJson>
  dispatch: (action: ModelAction) => void
  errors: ErrorsType
  idx: number
  readonly?: boolean
}

export const PimArtikelMengeneinheit = ({
  pimArtikelVersionMengeneinheit,
  mengeneinheiten,
  onChange,
  dispatch,
  errors,
  idx,
  readonly
}: PimArtikelMengeneinheitProps) => {

  const actions = (
    <>
      <Button
        variant="text"
        StartIcon={Delete}
        label="Entfernen"
        size="small"
        color="warning"
        onClick={() =>
          dispatch({
            type: EigenlistungsArtikelUseCase.REMOVEMENGENEINHEIT,
            me: pimArtikelVersionMengeneinheit
          })
        }
      />
    </>
  )

  const namePath = (field: string) =>
    `pimArtikel.version.mengeneinheiten.[__aid=${aidOf(pimArtikelVersionMengeneinheit)}].${field}`

  const namePath2 = (field: string) => `pimArtikel.version.mengeneinheiten.[${idx}].${field}`

  const umrechnungsInfo = useMemo(() => {
    if (
      pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.faktor &&
      pimArtikelVersionMengeneinheit.pimMengeneinheit &&
      pimArtikelVersionMengeneinheit.pimBasisMengeneinheit
    ) {
      if (pimArtikelVersionMengeneinheit.invertiert) {
        return (
          <Grid container spacing={1}>
            <Grid>
              {pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.faktor}
              <small>{pimArtikelVersionMengeneinheit.pimMengeneinheit?.name}</small>
            </Grid>
            <Grid>
              <ArrowForward fontSize="small" />
            </Grid>
            <Grid>
              1<small>{pimArtikelVersionMengeneinheit.pimBasisMengeneinheit?.name}</small>
            </Grid>
          </Grid>
        )
      } else {
        return (
          <Grid container spacing={1}>
            <Grid>
              1<small>{pimArtikelVersionMengeneinheit.pimMengeneinheit?.name}</small>
            </Grid>
            <Grid>
              <ArrowForward fontSize="small" />
            </Grid>
            <Grid>
              {pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.faktor}
              <small>{pimArtikelVersionMengeneinheit.pimBasisMengeneinheit?.name}</small>
            </Grid>
          </Grid>
        )
      }
    }
  }, [
    pimArtikelVersionMengeneinheit.invertiert,
    pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.faktor,
    pimArtikelVersionMengeneinheit.pimBasisMengeneinheit,
    pimArtikelVersionMengeneinheit.pimMengeneinheit
  ])

  return (
    <CardEx backgroundColor="default" title="Mengeneinheit" topActions={readonly ? null : actions}>
      <Grid container spacing={2} padding={1}>
        <Grid container spacing={2} size={{ xs: 12 }}>
          <Grid size={{ xs: 6, md: 4, lg: 2 }}>
            <TextField
              label="GTIN"
              value={pimArtikelVersionMengeneinheit.gtin}
              onChange={onChange}
              name={namePath('gtin')}
              error={resolveObjectField(errors, namePath2('gtin'))}
              regex={/^\d+$/}
              fullWidth
              disabled={readonly}
            />
          </Grid>
          <Grid size={{ xs: 6, md: 3, lg: 2 }}>
            <PimMengeneinheitField
              label="Mengeneinheit"
              value={pimArtikelVersionMengeneinheit.pimMengeneinheit}
              values={mengeneinheiten}
              onChange={onChange}
              error={resolveObjectField(errors, namePath2('pimMengeneinheit'))}
              name={namePath('pimMengeneinheit')}
              fullWidth
              disabled={readonly}
              required
            />
          </Grid>
          <Grid size={{ xs: 6, md: 4, lg: 2 }}>
            <PimMengeneinheitField
              label="Basis-Mengeneinheit"
              value={pimArtikelVersionMengeneinheit.pimBasisMengeneinheit}
              values={mengeneinheiten}
              onChange={onChange}
              error={resolveObjectField(errors, namePath2('pimBasisMengeneinheit'))}
              name={namePath('pimBasisMengeneinheit')}
              fullWidth
              disabled={readonly}
            />
          </Grid>
          <Grid container spacing={2} size={{ xs: 12 }}>
            <Grid size={{ xs: 6, md: 3, lg: 2, xl: 1 }}>
              <NumberField
                label="Basismengeneinheit (Faktor)"
                value={pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.faktor}
                onChange={onChange}
                error={resolveObjectField(errors, namePath2('mengeBasismengeneinheit.faktor'))}
                name={namePath('mengeBasismengeneinheit.faktor')}
                fraction={6}
                thousandSeparator
                fullWidth
                helperText={
                  pimArtikelVersionMengeneinheit.mengeBasismengeneinheit?.nenner &&
                  `Nenner: ${pimArtikelVersionMengeneinheit.mengeBasismengeneinheit.nenner}, Zähler: ${pimArtikelVersionMengeneinheit.mengeBasismengeneinheit.zaehler}`
                }
                disabled={readonly}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 3, lg: 2, xl: 1 }}>
              <StaticField label="Umrechnung" value={umrechnungsInfo} fullWidth />
            </Grid>
            <Grid>
              <Checkbox
                label="Faktorbezug invertiert"
                checked={pimArtikelVersionMengeneinheit.invertiert}
                name={namePath('invertiert')}
                size="small"
                onChange={onChange}
                disabled={readonly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Länge"
            value={pimArtikelVersionMengeneinheit.laenge}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('laenge'))}
            name={namePath('laenge')}
            fraction={0}
            suffix=" mm"
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Breite"
            value={pimArtikelVersionMengeneinheit.breite}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('breite'))}
            name={namePath('breite')}
            fraction={0}
            suffix=" mm"
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Höhe"
            value={pimArtikelVersionMengeneinheit.hoehe}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('hoehe'))}
            name={namePath('hoehe')}
            fraction={0}
            suffix=" mm"
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Gewicht Brutto"
            value={pimArtikelVersionMengeneinheit.gewichtBrutto}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('gewichtBrutto'))}
            name={namePath('gewichtBrutto')}
            fraction={6}
            suffix=" kg"
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Gewicht Netto"
            value={pimArtikelVersionMengeneinheit.gewichtNetto}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('gewichtNetto'))}
            name={namePath('gewichtNetto')}
            fraction={6}
            suffix=" kg"
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1}}>
          <NumberField
            label="Volumen"
            value={pimArtikelVersionMengeneinheit.volumen}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('volumen'))}
            name={namePath('volumen')}
            fraction={6}
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3, lg: 2, xl:1 }}>
          <NumberField
            label="Stapelfaktor"
            value={pimArtikelVersionMengeneinheit.stapelfaktor}
            onChange={onChange}
            error={resolveObjectField(errors, namePath2('stapelfaktor'))}
            name={namePath('stapelfaktor')}
            fraction={0}
            thousandSeparator
            fullWidth
            disabled={readonly}
          />
        </Grid>
      </Grid>
    </CardEx>
  )
}

export const PimArtikelMengeneinheitMemo = React.memo(PimArtikelMengeneinheit)
